import React, { useEffect, useState } from "react"
import Tabs from "../Tabs"
import AdminInfoAboutSection from "./AdminInfoAboutSection"
import AdminInfoGallerySection from "./AdminInfoGallerySection"
import AdminInfoOverviewDataSection from "./admin-info-oveviev/AdminInfoOverviewDataSection"
import AdminInfoOverviewLocationSection from "./admin-info-oveviev/AdminInfoOverviewLocationSection"
import AdminInfoOverviewEducationalProgramSection from "./admin-info-oveviev/AdminInfoOverviewEducationalProgramSection"
import AdminInfoOverviewKeyDataSection from "./admin-info-oveviev/AdminInfoOverviewKeyDataSection"
import AdminInfoFeesCurrentYear from "./admin-info-fees/AdminInfoFeesCurrentYear"
import { useCurrentUser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import axios from "../../http/axios"
import AdminInfoSubjectSection from "./admin-info-subject/AdminInfoSubjectSection"
import AdminInfoSportsSection from "./admin-info-sports/AdminInfoSportsSection"
import AdminScholarshipsSection from "./AdminScholarshipsSections"
import AdminInfoStudyCenterSection from "./AdminInfoStudyCenterSection"
import AdminInfoOverviewAdditionalOptions from "./admin-info-oveviev/AdminInfoOverviewAdditionalOptions"
import PlusIcon from "../../svg/plus-icon.inline.svg"

const AdminInfoSection = () => {
  const { currentUser } = useCurrentUser()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [feesArray, setFeesArray] = useState([])
  const [studyCenterArray, setStudyCenterArray] = useState([])
  const [isSummerSchool] = useState(!!currentUser.schoolMode)
  const [addStudyCenterArray, seAddStudyCenterArray] = useState(true)

  const [bodyOverview, setBodyOverview] = useState("keyData")
  const [isActiveOverview, setActiveOverview] = useState(true)

  // Sports
  const [, setActiveSports] = useState(false)

  const [accordionClicked, setAccordionClicked] = useState(true)
  const [accordionFeesClicked, setAccordionFeesClicked] = useState(false)

  const [change, setChange] = useState("")

  let expandedAccordion = accordionClicked ? "accordion-arrow" : ""
  let expandedFeesAccordion = accordionFeesClicked ? "accordion-arrow" : ""
  let expandedStudyCenterAccordion = accordionStudyCenterClicked
    ? "accordion-arrow"
    : ""

  useEffect(async () => {
    seAddStudyCenterArray(true)
    if (!change) {
      const pathname = window.location.pathname
      window.history.pushState(pathname, "", "#key-data")
    }
    if (currentUser) {
      if (isSummerSchool) {
        await axios
          .get(
            `summer-schools/${currentUser.schoolId}/study-centers` +
              `?lang=${lang}`
          )
          .then(res => {
            setStudyCenterArray(res.data)
          })
          .catch()
      }
      await axios
        .get(
          `/${isSummerSchool ? "summer-schools" : "schools"}/${
            currentUser.schoolId
          }/fees` + `?lang=${lang}`
        )
        .then(res => {
          setFeesArray(res.data)
        })
        .catch()
    }
  }, [currentUser, change])

  // Fees
  const [bodyFees, setBodyFees] = useState(feesArray.length && feesArray[0])
  const [isActiveFees, setActiveFees] = useState(false)

  // Study Center
  const [bodyStudyCenter, setBodyStudyCenters] = useState(
    studyCenterArray.length && studyCenterArray[0]
  )
  const [isActiveStudyCenter, setActiveStudyCenter] = useState(false)
  const [
    accordionStudyCenterClicked,
    setAccordionStudyCenterClicked,
  ] = useState(false)

  const handleToggle = name => {
    setChange(name)

    if (
      name === "closeAll" ||
      name === "about" ||
      name === "gallery" ||
      name === "scholarships" ||
      name === "subject" ||
      name === "sport"
    ) {
      setActiveStudyCenter(false)
      setActiveFees(false)
      setActiveOverview(false)
      changeBodyOverview("firstElementSelect", "firstElementSelect")
    }

    if (name === "overview") {
      setActiveFees(false)
      setActiveStudyCenter(false)
      setActiveOverview(!isActiveOverview)

      if (!accordionClicked) {
        setAccordionClicked(true)
      }
      if (accordionClicked) {
        setAccordionClicked(false)
      }
    }

    if (name === "fees") {
      setActiveStudyCenter(false)
      setActiveOverview(false)
      setActiveFees(!isActiveFees)
      if (!accordionFeesClicked) {
        setAccordionFeesClicked(true)
      }
      if (accordionFeesClicked) {
        setAccordionFeesClicked(false)
      }
    }
    if (name === "studyCenter") {
      setActiveStudyCenter(!isActiveStudyCenter)
      setActiveOverview(false)
      setActiveFees(false)
      if (!accordionStudyCenterClicked) {
        setAccordionStudyCenterClicked(true)
      }
      if (accordionStudyCenterClicked) {
        setAccordionStudyCenterClicked(false)
      }
    }
    if (name === "sport") {
      setActiveSports(!isActiveFees)
    }
    addHashUrl(name)
  }

  const addNewStudyCentres = () => {
    seAddStudyCenterArray(false)
    setStudyCenterArray(prev => {
      return [...prev, { id: 0, name: "New Study center", languageCode: "en" }]
    })
  }

  const addHashUrl = name => {
    const pathname = window.location.pathname
    switch (name) {
      case "keyData":
        window.history.pushState(pathname, "", "#key-data")
        break
      case "overview":
        window.history.pushState(pathname, "", "#key-data")
        break
      case "educationalProgrammes":
        window.history.pushState(pathname, "", "#study-programmes")
        break
      case "additionalOptions":
        window.history.pushState(pathname, "", "#additional-options")
        break
      case "contacts":
        window.history.pushState(pathname, "", "#contacts")
        break
      case "location":
        window.history.pushState(pathname, "", "#location")
        break
      case "fees":
        window.history.pushState(pathname, "", "#fees")
        break
      case "studyCenter":
        window.history.pushState(pathname, "", "#study-center")
        break
      case "sport":
        window.history.pushState(pathname, "", "#sports")
        break
      case "about":
        window.history.pushState(pathname, "", "#about")
        break
      case "gallery":
        window.history.pushState(pathname, "", "#gallery")
        break
      case "subject":
        window.history.pushState(pathname, "", "#subjects")
        break
      case "scholarships":
        window.history.pushState(pathname, "", "#scholarships")
        break
      default:
    }
  }

  const changeBodyOverview = (item, name) => {
    if (item?.id !== 0 && name !== "studyCenter") {
      setChange(item)
    }

    if (name === "firstElementSelect") {
      setBodyOverview("keyData")
      setBodyFees({ id: 3 })
    }

    if (
      name === "keyData" ||
      name === "educationalProgrammes" ||
      name === "contacts" ||
      name === "location" ||
      name === "additionalOptions"
    ) {
      setBodyOverview(item)
    }
    if (name === "fees") {
      setBodyOverview("keyData")
      setBodyFees(item)
    }

    if (name === "studyCenter") {
      setBodyStudyCenters(item)
    }
    addHashUrl(name)
  }

  function setChangeAll(item) {
    if (item === "setNewStudyCenters") {
      seAddStudyCenterArray(true)
    }
    setChange(item)
  }

  const getYearFromADate = date => {
    return date
  }

  const currentYear = new Date().getFullYear()

  function resolveYears(item) {
    if (currentYear + 1 === Number(getYearFromADate(item.year))) {
      return (
        <div>
          {t("schoolAdmin.nextYear")} {getYearFromADate(item.year)}
        </div>
      )
    } else {
      return <div>{getYearFromADate(item.year)}</div>
    }
  }

  const data = [
    {
      heading: (
        <div className="spoiler-item">
          <div
            onClick={() => handleToggle("overview")}
            className={`section-title spoiler-title ${expandedAccordion}`}
          >
            {t("school.header.overview")}
          </div>
          {isActiveOverview && (
            <div className="spoiler-content">
              <div
                onClick={() => changeBodyOverview("keyData", "keyData")}
                className={`spoiler-content-item ${
                  bodyOverview === "keyData" && "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.keydata.keyDataTitle")}</div>
              </div>
              <div
                onClick={() =>
                  changeBodyOverview(
                    "educationalProgrammes",
                    "educationalProgrammes"
                  )
                }
                className={`spoiler-content-item ${
                  bodyOverview === "educationalProgrammes" &&
                  "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.keydata.edProgrammes")}</div>
              </div>
              <div
                onClick={() => changeBodyOverview("location", "location")}
                className={`spoiler-content-item ${
                  bodyOverview === "location" && "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.location.location")}</div>
              </div>
              <div
                onClick={() => changeBodyOverview("contacts", "contacts")}
                className={`spoiler-content-item ${
                  bodyOverview === "contacts" && "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.header.contacts")}</div>
              </div>
            </div>
          )}
        </div>
      ),
      body: (
        <div>
          {bodyOverview === "keyData" && <AdminInfoOverviewKeyDataSection />}
          {bodyOverview === "educationalProgrammes" && (
            <AdminInfoOverviewEducationalProgramSection />
          )}
          {bodyOverview === "location" && <AdminInfoOverviewLocationSection />}
          {bodyOverview === "contacts" && <AdminInfoOverviewDataSection />}
        </div>
      ),
    },
    {
      heading: (
        <div
          onClick={() => handleToggle("about")}
          className="section-title section-item"
        >
          {t("school.header.about")}
        </div>
      ),
      body: <AdminInfoAboutSection />,
    },
    {
      heading: (
        <div
          onClick={() => handleToggle("gallery")}
          className="section-title section-item"
        >
          {t("school.header.gallery")}
        </div>
      ),
      body: <AdminInfoGallerySection />,
    },
    {
      heading: (
        <div className="spoiler-item">
          <div
            onClick={() => {
              changeBodyOverview(feesArray[0] ? feesArray[0] : null, "fees")
              handleToggle("fees")
            }}
            className={`section-title spoiler-title ${expandedFeesAccordion}`}
          >
            {t("school.header.fees")}
          </div>

          {isActiveFees && (
            <div className="spoiler-content">
              {feesArray &&
                feesArray.length &&
                feesArray.map(item => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => changeBodyOverview(item, "fees")}
                      className={`spoiler-content-item ${
                        bodyFees?.id === item.id && "spoiler-content-item-color"
                      }`}
                    >
                      {currentYear.toString() ===
                      getYearFromADate(item.year) ? (
                        <div>
                          {t("schoolAdmin.currYear")}{" "}
                          {getYearFromADate(item.year)}
                        </div>
                      ) : (
                        resolveYears(item)
                      )}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      ),
      body: (
        <div>
          <AdminInfoFeesCurrentYear setChange={setChangeAll} props={bodyFees} />
        </div>
      ),
    },
    {
      heading: (
        <div
          onClick={() => handleToggle("scholarships")}
          className="section-title section-item"
        >
          {t("school.header.scholarships")}
        </div>
      ),
      body: <AdminScholarshipsSection />,
    },

    {
      heading: (
        <div
          onClick={() => handleToggle("subject")}
          className="section-title section-item"
        >
          {t("school.header.subjects")}
        </div>
      ),
      body: <AdminInfoSubjectSection />,
    },
    {
      heading: (
        <div
          onClick={() => handleToggle("sport")}
          className="section-title section-item "
        >
          {t("school.header.sportsAndActivities")}
        </div>
      ),
      body: <AdminInfoSportsSection />,
    },
  ]

  const dataSummer = [
    {
      heading: (
        <div className="spoiler-item">
          <div
            onClick={() => handleToggle("overview")}
            className={`section-title spoiler-title ${expandedAccordion}`}
          >
            {t("school.header.overview")}
          </div>
          {isActiveOverview && (
            <div className="spoiler-content">
              <div
                onClick={() => changeBodyOverview("keyData", "keyData")}
                className={`spoiler-content-item ${
                  bodyOverview === "keyData" && "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.keydata.keyDataTitle")}</div>
              </div>
              <div
                onClick={() =>
                  changeBodyOverview(
                    "educationalProgrammes",
                    "educationalProgrammes"
                  )
                }
                className={`spoiler-content-item ${
                  bodyOverview === "educationalProgrammes" &&
                  "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.keydata.edProgrammes")}</div>
              </div>

              <div
                onClick={() =>
                  changeBodyOverview("additionalOptions", "additionalOptions")
                }
                className={`spoiler-content-item ${
                  bodyOverview === "additionalOptions" &&
                  "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.keydata.adOptions")}</div>
              </div>
              <div
                onClick={() => changeBodyOverview("contacts", "contacts")}
                className={`spoiler-content-item ${
                  bodyOverview === "contacts" && "spoiler-content-item-color"
                }`}
              >
                <div>{t("school.header.contacts")}</div>
              </div>
            </div>
          )}
        </div>
      ),
      body: (
        <div>
          {bodyOverview === "keyData" && <AdminInfoOverviewKeyDataSection />}
          {bodyOverview === "educationalProgrammes" && (
            <AdminInfoOverviewEducationalProgramSection />
          )}
          {bodyOverview === "additionalOptions" && (
            <AdminInfoOverviewAdditionalOptions />
          )}
          {bodyOverview === "contacts" && <AdminInfoOverviewDataSection />}
        </div>
      ),
    },
    {
      heading: (
        <div
          onClick={() => handleToggle("about")}
          className="section-title section-item"
        >
          {t("school.header.about")}
        </div>
      ),
      body: <AdminInfoAboutSection />,
    },
    {
      heading: (
        <div
          onClick={() => handleToggle("gallery")}
          className="section-title section-item"
        >
          {t("school.header.gallery")}
        </div>
      ),
      body: <AdminInfoGallerySection />,
    },
    {
      heading: (
        <div className="spoiler-item">
          <div
            onClick={() => {
              changeBodyOverview(feesArray[0] ? feesArray[0] : null, "fees")
              handleToggle("fees")
            }}
            className={`section-title spoiler-title ${expandedFeesAccordion}`}
          >
            {t("school.header.fees")}
          </div>

          {isActiveFees && (
            <div>
              <div className="spoiler-content">
                {feesArray &&
                  feesArray.map(item => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => changeBodyOverview(item, "fees")}
                        className={`spoiler-content-item ${
                          bodyFees?.id === item.id &&
                          "spoiler-content-item-color"
                        }`}
                      >
                        {currentYear.toString() ===
                        getYearFromADate(item.year) ? (
                          <div>
                            {t("schoolAdmin.currYear")}{" "}
                            {getYearFromADate(item.year)}
                          </div>
                        ) : (
                          resolveYears(item)
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
        </div>
      ),
      body: (
        <div>
          <AdminInfoFeesCurrentYear setChange={setChange} props={bodyFees} />
        </div>
      ),
    },
    {
      heading: (
        <div className="spoiler-item">
          <div
            onClick={() => {
              changeBodyOverview(
                studyCenterArray[0] ? studyCenterArray[0] : null,
                "studyCenter"
              )
              handleToggle("studyCenter")
            }}
            className={`section-title spoiler-title ${expandedStudyCenterAccordion}`}
          >
            {t("school.header.study-centers")}
          </div>

          {isActiveStudyCenter && (
            <div>
              <div className="spoiler-content">
                {studyCenterArray &&
                  studyCenterArray.map(item => {
                    return (
                      <div
                        key={item.id}
                        onClick={() => changeBodyOverview(item, "studyCenter")}
                        className={`spoiler-content-item ${
                          bodyStudyCenter?.id === item.id &&
                          "spoiler-content-item-color"
                        }`}
                      >
                        {item?.name}
                      </div>
                    )
                  })}
              </div>
              {addStudyCenterArray && (
                <div
                  onClick={addNewStudyCentres}
                  style={{ display: "flex", marginTop: "10px" }}
                >
                  <PlusIcon style={{ marginRight: "10px", display: "block" }} />
                  Add new
                </div>
              )}
            </div>
          )}
        </div>
      ),
      body: (
        <div>
          <AdminInfoStudyCenterSection
            setChange={setChangeAll}
            props={bodyStudyCenter}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="global-wrapper admin-profile published-info">
        <div className="school-admin-info-wrapper">
          <div className="sections-list section-list-width container">
            <div className="left-title school-info-title-info">
              {t("schoolAdmin.schoolInfo")}
            </div>

            <Tabs data={isSummerSchool ? dataSummer : data} />
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminInfoSection
