import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../../components/CurrentUserContext"
import axios from "../../../http/axios"
import { notification } from "antd"


const AdminInfoOverviewLocationSection = () => {

  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const { currentUser } = useCurrentUser()
  const [isSummerSchool,] = useState(!!currentUser.schoolMode)
  const [locationArray, setLocationArray] = useState({
    settlement: "",
    regionId: "",
    transportStationId: "",
    settlementTypeId: "",
    timeToStation: 0,
    distanceToStation: 0
  })
  const [transportStationArray, setTransportStationArray] = useState([])
  const [regionArray, setRegionArray] = useState([])
  const [arrayLocations, setArrayLocations] = useState([])

  useEffect(async () => {
    if (currentUser) {
      await axios.get(`/${isSummerSchool ? 'summer-schools' : 'schools'}/${currentUser.schoolId}` + `?lang=${lang}`).then(res => {
        setLocationArray(res.data)
      })
    }
    await axios.get(`/lookups/regions/lookupresult` + `?lang=${lang}`).then(res => {
      setRegionArray(res.data)
    })

    await axios.get(`/transport-stations/lookupresult` + `?lang=${lang}`).then(res => {
      setTransportStationArray(res.data)
    })

    await axios.get(`/lookups/settlementTypes/lookupresult?lang=${i18n.language}`).then(res => {
      setArrayLocations(res.data)

    })
  }, [currentUser])

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setLocationArray(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    axios.put(`/${isSummerSchool ? 'summer-schools' : 'schools'}/${currentUser.schoolId}`, {
      ...locationArray,
      cabinetSection: "Location"
    }).then(() => {
      openNotification("topRight")
    })

  }

  return (
    <div className="info-overview-wrapper f-js overview-location" style={{ paddingBotton: "40px" }}>
      <form onSubmit={handleFormSubmit}>
        <div className="info-overview-content-wrapper">
          <div
            className="school-info-title right-title">{t('school.header.overview')}&nbsp;&nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;&nbsp;&nbsp;{t('school.location.location')}
          </div>
          <div className="school-data-section students-number-section school-data-input-field">
            <div className="school-data-section-title">{t('schoolAdmin.locationType')}</div>

            <div className="search-select-types-wrapper search-select-types-change">
              <div id="select-types" className="custom-select-wrapper ">
                <div className="custom-select input-group">

                  <div>
                    <select
                      id="settlementTypeId"
                      name="settlementTypeId"
                      onChange={handleChange}
                      value={locationArray.settlementTypeId || ''}
                      className="contactSelect contact-select-change"
                    >
                      <option hidden value=""/>

                      {arrayLocations.map(item => {
                        return (
                          <option
                            key={item.id}
                            value={item.id}
                          >
                            {item.value}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school-data-section students-number-section school-data-input-field">
            <div className="school-data-section-title">{t('schoolAdmin.region')}</div>

            {/* SELECT TYPES */}
            <div className="search-select-types-wrapper search-select-types-change">
              <div id="select-types" className="custom-select-wrapper ">
                <div className="custom-select input-group">

                  <div>
                    <select
                      id="categoryId"
                      name="regionId"
                      onChange={handleChange}
                      value={locationArray.regionId}
                      className="contactSelect contact-select-change"
                    >
                      <option defaultValue={locationArray.regionResolved} disabled hidden>
                        {locationArray.regionResolved}
                      </option>
                      {regionArray.map(regionItem => {
                        return (
                          <option
                            key={regionItem.id}
                            value={regionItem.id}
                          >
                            {regionItem.value}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school-data-section students-number-section school-data-input-field">
            <div className="school-data-section-title">{t('schoolAdmin.settlement')}</div>

            {/* SELECT TYPES */}
            <div className="search-select-types-wrapper">
              <div id="select-types" className="custom-select-wrapper ">
                <div className="custom-select input-group">
                  <input
                    type="text"
                    name="settlement"
                    onChange={handleChange}
                    value={locationArray.settlement}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="school-data-section students-age-section school-data-input-field">
            <div className="school-data-section-title">{t('schoolAdmin.nearestAirport')}</div>
            <div className="search-select-types-wrapper search-select-types-change">
              <div id="select-types" className="custom-select-wrapper ">
                <div className="custom-select input-group">

                  <div>
                    <select
                      id="categoryId"
                      value={locationArray.transportStationId}
                      name="transportStationId"
                      onChange={handleChange}
                      className="contactSelect contact-select-change"
                    >
                      <option defaultValue={locationArray.transportStationResolved} >
                        {locationArray.transportStationResolved}
                      </option>
                      {transportStationArray.map(variable => {
                        return (
                          <option
                            key={variable.id}
                            value={variable.id}
                          >
                            {variable.value}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="school-data-section international-percentage-section">
            <div className="school-data-section-title">
              Distance from airport (km)
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="distanceToStation"
                min="0"
                value={locationArray.distanceToStation}
              />
            </div>
          </div>
          <div className="school-data-section international-percentage-section">
            <div className="school-data-section-title">
              Time of travel from airport (minutes)
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="timeToStation"
                min="0"
                value={locationArray.timeToStation}
              />
            </div>
          </div>
          <button className="show-all-button save-info-btn students-info-btn school-admin-info-btn w-100" type="submit">
            <div>{t('main.userProfileMap.save')}</div>
          </button>
        </div>
      </form>
    </div>

  )
}

export default AdminInfoOverviewLocationSection
