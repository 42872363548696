import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { isBrowser, useCurrentUser } from "../../../components/CurrentUserContext"
import axios from "../../../http/axios"
import CropperPhotoContacts from "../../../components/CroppPhotoContacts"
import UpdatePhotoIcon from "../../../svg/update-photo-icon.inline.svg"
import { notification } from "antd"
let ReactQuill = null
if (isBrowser()) {
  ReactQuill = require("react-quill")
}

const AdminInfoOverviewKeyDataSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const isMounted = useRef(null);

  const { currentUser } = useCurrentUser()
  const [generalSetting, setGeneralSetting] = useState({
    name: "",
    foundationYear: "",
    quoteGeneral: "",
    quote: "",
    resolvedImageUrl: "",
    imageUrl: "",
    resolvedCroppedImageUrl: "",
    croppedImageUrl: "",
    logoUrl: "",
    resolvedLogoUrl: "",
    studyCentersNumber: "",
    schoolToOrganisationTypesIds: []
  })

  const [allSetting, setAllSetting] = useState({
    numberOfStudents: "",
    ageMin: "",
    ageMax: "",
    boardersAgeMin: "",
    boardersAgeMax: "",
    boardersAtSchool: "",
    internationalStudents: "",
    teacherToStudentsRatio: "",
    religionId: "",
    currentDate: "",
    tuitionHoursPerWeekMin: "",
    tuitionHoursPerWeekMax: "",
    maxClassSize: ""

  })
  const [religionArray, setReligionArray] = useState([])
  const [schoolTypesArray, setSchoolTypesArray] = useState([])
  const [organisationTypes, setOrganisationTypes] = useState([])

  const [isSummerSchool, ] = useState(!!currentUser.schoolMode)
  useEffect(async () => {
    isMounted.current = true;
    if (currentUser) {
      axios.get(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}?lang=${lang}`).then(res => {
        setGeneralSetting(prevState => {
          const currState = { ...prevState }
          Object.keys(currState).forEach(key => {
            currState[key] = res.data[key] || ""
          })
          return { ...res.data, ...currState }
        })
      })
      axios
        .get(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}/settings?lang=${lang}`)
        .then(res => {
          const religionId = res.data.religionId ? res.data.religionId : ""
          setAllSetting({ ...res.data, religionId })

          if (isSummerSchool) {
            axios
              .get(`/lookups/schoolOrganisationTypes/lookupresult` + `?lang=${lang}`)
              .then(resp => {
                let auditValue = resp.data?.map(item => {
                  item.isChecked = res.data.schoolToOrganisationTypesIds ? res.data.schoolToOrganisationTypesIds.includes(item.id) : false
                  return item
                })
                setOrganisationTypes(auditValue)
              })

          } else {
            axios
              .get(`/lookups/schoolTypes/lookupresult` + `?lang=${lang}`)
              .then(resp => {
                let auditValue = resp.data?.map(item => {
                  item.isChecked = res.data.schoolTypesIds ? res.data.schoolTypesIds.includes(item.id) : false
                  return item
                })
                setSchoolTypesArray(auditValue)
              })
          }


        })

      if (!isSummerSchool) {
        axios
          .get(`/lookups/religions/lookupresult` + `?lang=${lang}`)
          .then(res => {
            setReligionArray(res.data)
          })
      }

    }
    return () => {
      isMounted.current = false;
    }
  }, [])

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement
    })
  }

  const handleChange = e => {
    const { name, value } = e.target
    setAllSetting(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const changeDate = (e) => {
    setAllSetting(prevState => ({
      ...prevState,
      ['currentDate']: e
    }))
  }

  const handleGeneralChange = e => {
    const { name, value } = e.target
    setGeneralSetting(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    let dataResult

    if (isSummerSchool) {
      let selectOrganisationChecked = []
      organisationTypes.forEach(item =>
        item.isChecked ? selectOrganisationChecked.push(item.id) : null
      )

      dataResult = {
        ...allSetting,
        cabinetSection: "Key data",
        ageMin: Number(allSetting?.ageMin),
        ageMax: Number(allSetting?.ageMax),
        languageCode: lang,
        schoolToOrganisationTypesIds: selectOrganisationChecked,
      }
    } else {
      let selectArrayChecked = []
      schoolTypesArray.forEach(item =>
        item.isChecked ? selectArrayChecked.push(item.id) : null
      )
      dataResult = {
        ...allSetting,
        religionId: Number(allSetting.religionId),
        cabinetSection: "Key data",
        ageMin: Number(allSetting?.ageMin),
        ageMax: Number(allSetting?.ageMax),
        languageCode: lang,
        schoolTypesIds: selectArrayChecked
      }

    }

    axios.post(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}/settings`, dataResult).then(() => {
      openNotification("topRight")
    })

    axios.put(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}`, {
      ...generalSetting,
      cabinetSection: "Key data",
      languageCode: lang
    })
  }

  const changeSchoolType = (e, index) => {
    const arrayType = [...schoolTypesArray]
    arrayType[index]["isChecked"] = !arrayType[index]["isChecked"]
    setSchoolTypesArray(arrayType)
  }

  const changeOrganisationTypes = (e, index) => {
    const arrayType = [...organisationTypes]
    arrayType[index]["isChecked"] = !arrayType[index]["isChecked"]
    setOrganisationTypes(arrayType)
  }



  const changePhoto = (filePath, resolvedFilePath, index, name, resolveName) => {
    setGeneralSetting(prevState => ({
      ...prevState,
      [name]: filePath,
      [resolveName]: resolvedFilePath
    }))
  }

  return (
    <div className="info-overview-wrapper f-js">
      <div className="info-overview-content-wrapper">
        <div className="school-info-title right-title">
          {t("school.header.overview")}
          &nbsp;&nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;&nbsp;&nbsp;
          {t("school.keydata.keyDataTitle")}
        </div>
        <form onSubmit={handleFormSubmit}>
          <div
            className="school-data-section students-number-section"
            style={{ marginTop: "40px" }}
          >
            <div className="school-data-section-title">
              {t("main.userProfileMap.schoolName")}
            </div>
            <input
              type="text"
              name="name"
              onChange={handleGeneralChange}
              value={generalSetting.name}
            />
          </div>
          {isSummerSchool && <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              {t("main.userProfileMap.studyCNubmer")}
            </div>
            <input
              type="number"
              name="studyCentersNumber"
              onChange={handleGeneralChange}
              value={generalSetting.studyCentersNumber}
            />
          </div>}
          <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              {t("main.userProfileMap.foundationYear")}
            </div>
            <input
              type="number"
              name="foundationYear"
              onChange={handleGeneralChange}
              value={generalSetting.foundationYear}
            />
          </div>

          <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              Cover image (recommended size: 1680×960px)
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {generalSetting.resolvedImageUrl ?
                <img src={generalSetting.resolvedImageUrl} style={{ marginBottom: "0" }} height={48} width={48}
                     alt="" /> : <div className="previewText"> {t("main.userProfileMap.selectImage")}</div>}
              <UpdatePhotoIcon style={{ marginLeft: "10px" }} className="update-photo-icon updatePhoto" />
              <div className="update-photo-wrapper-dialog">
                <CropperPhotoContacts urlUpload={"/schools/files/upload"} name={"imageUrl"}
                                      resolveName={"resolvedImageUrl"} changePhoto={changePhoto} aspectRatio={1.75} />
              </div>
            </div>
          </div>

          <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              Cover preview image (recommended size: 670×560)
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {generalSetting.resolvedCroppedImageUrl ?
                <img src={generalSetting.resolvedCroppedImageUrl} style={{ marginBottom: "0" }} height={48} width={48}
                     alt="" /> : <div className="previewText"> {t("main.userProfileMap.selectImage")}</div>}
              <UpdatePhotoIcon style={{ marginLeft: "10px" }} className="update-photo-icon updatePhoto" />
              <div className="update-photo-wrapper-dialog"><CropperPhotoContacts urlUpload={"/schools/files/upload"}
                                                                                 name={"croppedImageUrl"}
                                                                                 resolveName={"resolvedCroppedImageUrl"}
                                                                                 aspectRatio={1.1964}
                                                                                 changePhoto={changePhoto} /></div>
            </div>
          </div>

          <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              Logo (Recommended size: 140x140)
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {generalSetting.logoUrl ?
                <img src={generalSetting.resolvedLogoUrl} style={{ marginBottom: "0" }} height={48} width={48}
                     alt="" /> : <div className="previewText"> {t("main.userProfileMap.selectImage")}</div>}
              <UpdatePhotoIcon style={{ marginLeft: "10px" }} className="update-photo-icon updatePhoto" />
              <div className="update-photo-wrapper-dialog"><CropperPhotoContacts urlUpload={"/schools/files/upload"}
                                                                                 name={"logoUrl"}
                                                                                 resolveName={"resolvedLogoUrl"}
                                                                                 changePhoto={changePhoto} /></div>
            </div>
          </div>
          {!isSummerSchool && <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              {t("main.userProfileMap.quoteGeneral")}
            </div>
            <input
              type="text"
              name="quoteGeneral"
              onChange={handleGeneralChange}
              value={generalSetting.quoteGeneral}
            />
          </div>}

          {!isSummerSchool && <div className="school-data-section students-number-section">

            <div className="school-data-section-title">
              {t("main.userProfileMap.quote")}
            </div>
            <input
              type="text"
              name="quote"
              onChange={handleGeneralChange}
              value={generalSetting.quote}
            />
          </div>}

          {isSummerSchool && <div className="school-data-section school-type-section">
            <div className="school-data-section-title">
              {t("search.organisationTypes")}
            </div>

            <div className="data-checkboxes-list">
              {organisationTypes &&
                organisationTypes.map((item, index) => {
                  return (
                    <div
                      onClick={event => changeOrganisationTypes(event, index)}
                      key={index}
                      className="data-checkbox-item"
                    >
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        name="schoolTypesIds"
                        id="schoolTypesIds"
                        onChange={() => {
                        }}
                        value={item.value}
                        checked={item.isChecked}
                      />
                      <label>{item.value}</label>
                    </div>
                  )
                })}
            </div>
          </div>}
          {isSummerSchool && <div className="school-data-section school-type-section">
            <div className="school-data-section-title">
              {t("search.currentDates")}
            </div>
            <ReactQuill
              theme="snow"
              name="currentDate"
              value={allSetting?.currentDate || ""}
              onChange={changeDate}
            />
          </div>}

          {!isSummerSchool && <div className="school-data-section school-type-section">
            <div className="school-data-section-title">
              {t("search.schoolType")}
            </div>

            <div className="data-checkboxes-list">
              {schoolTypesArray &&
                schoolTypesArray.map((item, index) => {
                  return (
                    <div
                      onClick={event => changeSchoolType(event, index)}
                      key={index}
                      className="data-checkbox-item"
                    >
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        name="schoolTypesIds"
                        id="schoolTypesIds"
                        onChange={() => {
                        }}
                        value={item.value}
                        checked={item.isChecked}
                      />
                      <label>{item.value}</label>
                    </div>
                  )
                })}
            </div>
          </div>}

          {!isSummerSchool && <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              {t("main.userProfileMap.numberOfStudetns")}
            </div>
            <input
              type="number"
              name="numberOfStudents"
              min="0"
              placeholder="Add"
              onChange={handleChange}
              style={{
                maxWidth: "200px"
              }}
              value={allSetting.numberOfStudents}
            />
          </div>}

       <div className="school-data-section students-age-section">
            <div className="school-data-section-title">
              {t("search.studentsAge")}
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                name="ageMin"
                min="0"
                onChange={handleChange}
                value={allSetting.ageMin}
              />
              <span>–</span>
              <input
                type="number"
                className="range-number-input"
                name="ageMax"
                min="0"
                onChange={handleChange}
                value={allSetting.ageMax}
              />
              <span className="section-range-text">{t("school.years")}</span>
            </div>
          </div>
          {isSummerSchool && <div className="school-data-section students-number-section">
            <div className="school-data-section-title">
              {t("search.maxClassSize")}
            </div>
            <input
              type="text"
              name="maxClassSize"
              onChange={handleChange}
              value={allSetting.maxClassSize}
            />
          </div>}

          {isSummerSchool && <div className="school-data-section students-age-section">
            <div className="school-data-section-title">
              {t("search.tuitionHoursPerWeek")}
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                name="tuitionHoursPerWeekMin"
                min="0"
                step="any"
                onChange={handleChange}
                value={allSetting.tuitionHoursPerWeekMin}
              />
              <span>–</span>
              <input
                type="number"
                className="range-number-input"
                name="tuitionHoursPerWeekMax"
                min="0"
                step="any"
                onChange={handleChange}
                value={allSetting.tuitionHoursPerWeekMax}
              />
            </div>
          </div>}

          {!isSummerSchool && <div className="school-data-section boarders-age-section">
            <div className="school-data-section-title">
              {t("schoolAdmin.boardersAge")}
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="boardersAgeMin"
                min="0"
                value={allSetting.boardersAgeMin}
              />
              <span>–</span>
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="boardersAgeMax"
                min="0"
                value={allSetting.boardersAgeMax}
              />
              <span className="section-range-text">{t("school.years")}</span>
            </div>
          </div>}
          {!isSummerSchool && <div className="school-data-section boarders-percentage-section">
            <div className="school-data-section-title">
              {t("schoolAdmin.boardersPercent")}
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="boardersAtSchool"
                min="0"
                value={allSetting.boardersAtSchool}
              />
              <span className="section-range-text">&#37;</span>
            </div>
          </div>}
          {!isSummerSchool && <div className="school-data-section international-percentage-section">
            <div className="school-data-section-title">
              {t("school.keydata.internationalStudents")}
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="internationalStudents"
                min="0"
                value={allSetting.internationalStudents}
              />
              <span className="section-range-text">&#37;</span>
            </div>
          </div>}
          {!isSummerSchool && <div className="school-data-section teachers-to-students-section">
            <div className="school-data-section-title">
              {t("school.keydata.teachers")}
            </div>
            <div className="student-section-range-input-wrapper f-ac">
              <span>1 :</span>
              <input
                type="number"
                className="range-number-input"
                onChange={handleChange}
                name="teacherToStudentsRatio"
                min="0"
                value={allSetting.teacherToStudentsRatio}
              />
            </div>
          </div>}

          <div className="school-data-section religion-section school-data-input-field">
            {!isSummerSchool &&
              <>
                <div className="school-data-section-title">
                  {t("school.keydata.religion")}
                </div>

                <div className="search-select-types-wrapper search-select-types-change">
                  <div id="select-types" className="custom-select-wrapper ">
                    <div className="custom-select input-group">
                      <div>
                        <select
                          id="categoryId"
                          name="religionId"
                          onChange={handleChange}
                          value={allSetting?.religionId}
                          className="contactSelect contact-select-change"
                        >
                          <option
                            defaultValue={allSetting?.religionValue}
                            disabled
                            hidden
                          >
                            {allSetting.religionValue}
                          </option>
                          <option hidden value=""/>

                          {religionArray.map((item, idx) => {
                            return (
                              <option key={idx} value={item.id}>
                                {item.value}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </>}


            <button
              className="show-all-button save-info-btn students-info-btn school-admin-info-btn"
              type="submit"
            >
              <div>{t("main.userProfileMap.save")}</div>
            </button>

          </div>

        </form>
      </div>
    </div>
  )
}

export default AdminInfoOverviewKeyDataSection
