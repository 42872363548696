import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "react-image-crop/dist/ReactCrop.css"
import ReactCrop from "react-image-crop"
import axios from "../http/axios"
import { useTranslation } from "react-i18next"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})


const CropperPhotoContacts = (props) => {
  const { t } = useTranslation()
  const [size, setSize] = useState({})
  const [widthImage, setWidthImage] = useState({})
  const [heightImage, setHeightImage] = useState({})


  useEffect(async () => {
    switch (props?.name) {
      case "imageUrl":
        setWidthImage(1680)
        setHeightImage(960)
        setSize({ maxWidth: "956px", maxHeight: "960px" })
        break
      case "croppedImageUrl":
        setWidthImage(670)
        setHeightImage(560)
        setSize({ maxWidth: "920px", maxHeight: "810px" })
        break
      case "logoUrl":
        setWidthImage(140)
        setHeightImage(140)
        setSize({ maxWidth: "283px", maxHeight: "283px" })
        break
      default:
        return
    }

  }, [props])
  const [accountsApplications, setAccountsApplications] = useState({
    croppedImageUrl: "",
    open: false,
    src: null,
    crop: {
      aspect: props.aspectRatio ? props.aspectRatio : 1
    }
  })

  const [imageRef, setImageRef] = useState("")
  const [blob, setBlob] = useState()


  let fileInput = React.createRef()

  const handleClickOpen = () => {
    setAccountsApplications(prev => ({ ...prev, "open": true }))
  }

  const handleClose = () => {
    setAccountsApplications(prev => ({ ...prev, "open": false }))
  }

  function savePhotoUpdate() {
    fileUpload(blob)
    handleClose()
  }


  function onSelectFile(e) {
    fileUploadNew(e)

    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setAccountsApplications(prev => ({ ...prev, "src": reader.result }))
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const fileUploadNew = (e) => {
    const url = props.urlUpload
    const formData = new FormData()
    formData.append("file", e.target.files[0])
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    axios.post(url, formData, config).then(response => {
      props.changePhoto(response.data.filePath, response.data.resolvedFilePath, props.index, props.name, props.resolveName)
    })
  }

  const onImageLoaded = image => {
    setImageRef(image)
  }

  const onCropComplete = crop => {
    makeClientCrop(crop)
  }

  const onCropChanges = (crop) => {
    setAccountsApplications(prev => ({ ...prev, "crop": crop }))

  }
  const fileUpload = (e) => {
    const url = props.urlUpload
    const formData = new FormData()

    if (e) {
      formData.append(e.name, e, e.name)
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
      axios.post(url, formData, config).then((response) => {
        props.changePhoto(response.data.filePath, response.data.resolvedFilePath, props.index, props.name, props.resolveName)
      })
    }

  }

  async function makeClientCrop(crop) {

    if (imageRef && crop.width && crop.height) {

      try {
        if (props?.name === "imageUrl") {
          await getCroppedLargeImg(
            imageRef,
            crop,
            "newFile.jpeg"
          )
        } else {
          await getCroppedImg(
            imageRef,
            crop,
            "newFile.jpeg"
          )
        }
      } catch (e) {
        console.log(e)
      }


    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas")
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve) => {
      canvas.toBlob(blobs => {
        if (!blobs) {
          return
        }
        blobs.name = fileName
        setBlob(blobs)
        let fileUrl = window.URL.createObjectURL(blobs)

        window.URL.revokeObjectURL(fileUrl)

        resolve(fileUrl)
      }, "image/jpeg")
    })
  }

  function getCroppedLargeImg(image, crop, fileName) {
    const canvas = document.createElement("canvas")

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width * 1 * scaleX
    canvas.height = crop.height * 1 * scaleY
    const ctx = canvas.getContext("2d")

    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve) => {
      canvas.toBlob(blobs => {
        if (!blobs) {
          return
        }
        blobs.name = fileName
        setBlob(blobs)
        let fileUrl = window.URL.createObjectURL(blobs)

        window.URL.revokeObjectURL(fileUrl)

        resolve(fileUrl)
      }, "image/jpeg")
    })
  }


  return (
    <div>
      <button type="button" htmlFor="files" onClick={handleClickOpen}
              className="selectImage position update-photo-contact">Upload a photo
      </button>

      <Dialog
        PaperProps={{ style: size }}
        open={accountsApplications.open}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle>{t("main.userProfileMap.selectImage")}</DialogTitle>
        <DialogContent>
          <input
            type="file"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={onSelectFile}
            multiple
          />
          {accountsApplications.src && (
            <ReactCrop
              width={widthImage}
              height={heightImage}
              src={accountsApplications.src}
              crop={accountsApplications.crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChanges}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleClose} color="primary">
            {t("schoolAdmin.close")}
          </Button>
          <Button type="button" onClick={() => fileInput.current.click()}>
            {accountsApplications.src === null ? t("schoolAdmin.uploadPhoto") : t("schoolAdmin.changePhoto")}
          </Button>
          {accountsApplications.src !== null ? (
            <Button type="button" onClick={savePhotoUpdate}>
              {t("schoolAdmin.savePhoto")}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  )

}

export default CropperPhotoContacts
