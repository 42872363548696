import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../../components/CurrentUserContext"
import axios from "../../../http/axios"
import { notification } from "antd"
import CropperPhotoContacts from "../../../components/CroppPhotoContacts"
import UpdatePhotoIcon from "../../../svg/update-photo-icon.inline.svg"

const AdminInfoOverviewDataSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const { currentUser, fetchCurrentUser } = useCurrentUser()

  const defaultContact = {
    firstname: "",
    lastname: "",
    position: "",
    imageUrl: "",
    resolvedImageUrl: "",
    languageCode: lang,
    email: "",
    id: 0
  }
  const [isSummerSchool, ] = useState(!!currentUser.schoolMode)
  const [allInputValue, setAlInputValue] = useState([])
  const [tmpContactIds, setTmpContactIds] = useState([])
  const [loading, setLoading] = useState(false)
  const isMounted = useRef(null);

  useEffect(async () => {

    if (currentUser) {
      fetchManagers()
    }
    return () => {
      isMounted.current = false;
    }
  }, [currentUser])

  const fetchManagers = () =>  {
    isMounted.current = true;

    setLoading(true)
    axios
      .get(`/${isSummerSchool ? 'summer-schools' : 'schools'}/${currentUser.schoolId}/managers?lang=${lang}`)
      .then(res => {
        let data = res.data.map(x => {
          x.languageCode = lang
          return x;
        })
        if (isMounted) {
          setAlInputValue(data)
          setLoading(false)
        }
      }).catch(() => {
      setLoading(false)
    })
  }

  const changePhoto =(filePath, resolvedFilePath, index, name, resolveName)=>{
    const list = [...allInputValue]
    list[index]["resolvedImageUrl"] = resolvedFilePath
    list[index]["imageUrl"] = filePath
    setAlInputValue(list)
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target

    const list = [...allInputValue]
    list[index][name] = value
    setAlInputValue(list)
  }

  function handleFormSubmit(e, item, index) {
    e.preventDefault()
    if (loading) {
      return;
    }
    setLoading(true)
    const urlPrefix = isSummerSchool ? 'summer-schools' : 'schools';
    if (item.id) {
      axios.put(
        `/${urlPrefix}/${item.schoolId}/managers/${item.id}`,{
        ...allInputValue[index],
        cabinetSection:"Contacts"}
      ).then(() => {
        openNotification("topRight")
        setLoading(false)
        fetchCurrentUser(true)
      }).catch((e) => {
        setLoading(false)
        notification.error({
          message: e.response.data.message,
          placement: "bottomRight",
        })
      })
    } else {
      const body = allInputValue[index];
      body.firstName = body.firstname;
      body.lastName = body.lastname;
      axios.post(
        `/${urlPrefix}/${item.schoolId}/managers`,{
        ...body,
        cabinetSection:"Contacts"}
      ).then(() => {
        openNotification("topRight")
        setLoading(false)
        fetchManagers()
      }).catch((e) => {
        setLoading(false)
        notification.error({
          message: e.response.data.message,
          placement: "bottomRight",
        })
      })
    }

  }

  const onAddContact = () => {
    setAlInputValue(prevState => {
      const current = prevState.slice()
      defaultContact.tmpContactId = tmpContactIds.length + 1;
      defaultContact.schoolId = currentUser.schoolId;
      setTmpContactIds(prev => {
        prev.push(defaultContact.tmpContactId)
        return prev
      })

      current.unshift(defaultContact)
      return current;
    })
  }

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  const onCancelAddContact = (tmpId) => {
    setAlInputValue(prevState => {
      setTmpContactIds(prev => {
        return prev.filter(x => x !== tmpId)
      })
      return prevState.slice().filter(x => x.tmpContactId !== tmpId);
    })
  }

  const markAsMain = manager => {
    if (currentUser) {
      axios
        .put(`/${isSummerSchool ? 'summer-schools' : 'schools'}/${currentUser.schoolId}/managers/${manager.id}/mark-as-main`)
        .then(() => {
          fetchManagers()
        }).catch((e) => {
        notification.error({
          message: e.response.data.message,
          placement: "topRight",
        })
      })
    }
  }

  return (
    <div className="info-overview-wrapper f-js contacts-overview">
      <div className="school-info-title right-title" >
        {t("school.header.overview")}
        &nbsp;&nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;&nbsp;&nbsp;
        {t("school.header.contacts")}
      </div>

      <div style={{marginBottom: '40px'}}>
        <button
          className="show-all-button save-info-btn students-info-btn school-admin-info-btn py-0"
          type={'button'} onClick={onAddContact}>{t("main.userProfileMap.addContact")}</button>
      </div>

      {allInputValue &&
        allInputValue.map((item, index) => {
          return (
            <div key={item.id + (item.tmpContactId ? `_${item.tmpContactId}` : '')} className="info-overview-content-wrapper">
              <form onSubmit={event => handleFormSubmit(event, item, index)}>
                <div className="school-data-section students-number-section school-data-input-field">
                  <div className="school-data-section-title">
                    {item.id !== 0 ? t("schoolAdmin.advisor") : t("main.userProfileMap.newContact")}
                    {item.isMain && 
                      <span className="school-data-section-title">
                        &nbsp;(Main manager)
                      </span>
                    }
                  </div>
                  
                  
                  <div className="image-changing-block f-ac">
                    { item.resolvedImageUrl ? <img src={item.resolvedImageUrl} style={{marginBottom: '0'}} height={48} width={48} alt=''/>: <div className="previewText"> {t('main.userProfileMap.selectImage')}</div>}
                    <UpdatePhotoIcon style={{marginLeft:'10px'}} className="update-photo-icon updatePhoto"/>
                    <div className="update-photo-wrapper-dialog"><CropperPhotoContacts urlUpload={'/schools/files/upload'} changePhoto={changePhoto} index={index} /></div>
                  </div>
                  <div className="input-group">
                    <input
                      onChange={event => handleChange(event, index)}
                      value={item.firstname}
                      type="text"
                      name="firstname"
                      placeholder={t("main.header.firstName")}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <input
                      onChange={event => handleChange(event, index)}
                      value={item.lastname}
                      type="text"
                      name="lastname"
                      placeholder={t("main.header.lastName")}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <input
                      onChange={event => handleChange(event, index)}
                      value={item.position}
                      type="text"
                      name="position"
                      placeholder={t("main.header.position")}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <input
                      onChange={event => handleChange(event, index)}
                      value={item.email}
                      type="email"
                      name="email"
                      placeholder={t("main.header.email")}
                      required
                    />
                  </div>
                  {!item.isMain && item.id !== 0 && (
                        <div
                        style={{
                          maxWidth:"200px",
                          textAlign:"center"
                        }}
                          className="request-answer-btn curs-P"
                          onClick={() => {
                            markAsMain(item)
                          }}
                        >
                          Mark as main
                        </div>
                      )}
                  <button
                    className="show-all-button save-info-btn students-info-btn school-admin-info-btn"
                    type="submit"
                  >
                    <div>{t("main.userProfileMap.save")}</div>
                  </button>
                  { item.id === 0 &&
                    <button
                      style={{marginTop: 0}}
                      className="show-all-button save-info-btn students-info-btn school-admin-info-btn"
                      type="button"
                      onClick={() => onCancelAddContact(item.tmpContactId)}
                    >
                      <div>{t("main.userProfileMap.cancel")}</div>
                    </button>
                  }
                </div>
              </form>
            </div>
          )
        })}
    </div>
  )
}

export default AdminInfoOverviewDataSection
