import React, { useEffect, useState } from "react"
import { useCurrentUser } from "../../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import axios from "../../../http/axios"
import SchoolItemSubjectsSection from "../../schools/SchoolItemSubjectsSection"

import Table from "../../../components/Table"


const AdminInfoSubjectSection = () => {
  const { currentUser } = useCurrentUser()
  const { i18n } = useTranslation()
  const lang = i18n.language
  const [rowsArray, setRowsArray] = useState([])
  const [colsArray, seColsArray] = useState([])

  useEffect(async () => {
    if (currentUser) {
      await axios.get(`/schools/${currentUser.schoolId}/subjects` + `?lang=${lang}`).then(res => {
        setRowsArray(res.data)
      }).catch()
    }
    await axios.get(`/lookups/schoolCertificates/lookupresult` + `?lang=${lang}`).then(res => {
      seColsArray(res.data)

    }).catch()
  }, [currentUser])

 const onUncheck = (body, cert)=> {
    const selectSubj = {
      "schoolSubjects": [
        { ...body,  'certificateId': cert}
      ]
    }
  axios.post(`/schools/${currentUser.schoolId}/subjects/batch-delete`, {
    ...selectSubj,
    cabinetSection:"Subjects"}).then().catch()

  }

  const onCheck = (body, cert) => {
    const selectSubj = {
      "schoolSubjects": [
        { ...body,  'certificateId': cert}
      ]
    }
    axios.post(`/schools/${currentUser.schoolId}/subjects/batch-save`, {
      ...selectSubj,
      cabinetSection:"Subjects"}).then().catch()

  }

  const changeChecked = (subj, col, index) => {
    const subjectIds = subj.certificatesIds ? subj.certificatesIds : []
    let changeSelect
    if (!subjectIds.includes(col)){
      onCheck(subj, col)
      changeSelect = { ...subj, "certificatesIds": [...subjectIds, col] }
    } else(
      onUncheck(subj, col),
      changeSelect = {...subj,  "certificatesIds" : subjectIds.filter(item => item !== col) }
    )

    const list = [...rowsArray]
    list[index] = changeSelect
    setRowsArray(list)
  }

  return (
    <div className="info-overview-wrapper f-js subjects-table-block">
      {/* <Table certs={colsArray} subjects={rowsArray} changeChecked={changeChecked} /> */}
      <SchoolItemSubjectsSection subjects={rowsArray} certs={colsArray}
                                 changeChecked={changeChecked} style='white'></SchoolItemSubjectsSection>
    </div>
  )
}
export default AdminInfoSubjectSection
