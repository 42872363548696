import React, { useEffect, useState } from "react"
import axios from "../../http/axios"
import { isBrowser, useCurrentUser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import { notification } from "antd"
import UpdatePhotoIcon from "../../svg/update-photo-icon.inline.svg"
import CropperPhotoContacts from "../../components/CroppPhotoContacts"

let ReactQuill = null
if (isBrowser()) {
  ReactQuill = require("react-quill")
}
const AdminInfoStudyCenterSection = ({ props, setChange }) => {
  const { currentUser } = useCurrentUser()
  const [studyCenterArray, setStudyCenterArray] = useState({})
  const [lookupsAccomArray, setLookupsAccomArray] = useState([])
  const [lookupsoursesArray, setLookupsoursesArray] = useState([])
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language

  useEffect(async () => {
    axios.get(`/lookups/accommodations/lookupresult`).then(res => {
      setLookupsAccomArray(res.data)
    })
    if (currentUser?.schoolId) {
      axios
        .get(`/summer-schools/${currentUser.schoolId}/fees/courses/lookup`)
        .then(res => {
          let auditValue = res.data?.map(item => {
            item.isChecked =
              props && props.coursesIds
                ? props.coursesIds.includes(item.id)
                : false
            return item
          })
          setLookupsoursesArray(auditValue)
        })
    }

    setStudyCenterArray(props)
  }, [props])

  const handleChange = e => {
    const { name, value } = e.target
    setStudyCenterArray(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const changePhoto = (
    filePath,
    resolvedFilePath,
    index,
    name,
    resolveName
  ) => {
    setStudyCenterArray(prevState => ({
      ...prevState,
      ["resolvedImageUrl"]: resolvedFilePath,
      ["imageUrl"]: filePath,
    }))
  }

  const changeEditorData = (event, name) => {
    setStudyCenterArray(prevState => ({
      ...prevState,
      [name]: event,
    }))
  }

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  const changeCoursesTypes = (e, index) => {
    const arrayType = [...lookupsoursesArray]
    arrayType[index]["isChecked"] = !arrayType[index]["isChecked"]
    setLookupsoursesArray(arrayType)
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    studyCenterArray.languageCode = currentLang
    let selectCoursesOptionsChecked = []
    lookupsoursesArray.forEach(item =>
      item.isChecked ? selectCoursesOptionsChecked.push(item.id) : null
    )
    if (studyCenterArray?.id) {
      axios
        .put(
          `/summer-schools/${currentUser.schoolId}/study-centers/${studyCenterArray.id}`,
          {
            ...studyCenterArray,
            ageMin: Number(studyCenterArray?.ageMin),
            ageMax: Number(studyCenterArray?.ageMax),
            coursesIds: selectCoursesOptionsChecked,
          }
        )
        .then(() => {
          setChange("newStudyCenters")
          openNotification("topRight")
        })
        .catch(e => {
          notification.error({
            message: e.response.data.message,
            placement: "topRight",
          })
        })
    } else {
      axios
        .post(`/summer-schools/${currentUser.schoolId}/study-centers`, {
          ...studyCenterArray,
          ageMin: Number(studyCenterArray?.ageMin),
          ageMax: Number(studyCenterArray?.ageMax),
          coursesIds: selectCoursesOptionsChecked,
        })
        .then(() => {
          setChange("setNewStudyCenters")
          openNotification("topRight")
        })
        .catch(e => {
          notification.error({
            message: e.response.data.message,
            placement: "topRight",
          })
        })
    }
  }

  return (
    <div className="info-overview-wrapper f-js fees-block overview-location">
      <div className="info-overview-content-wrapper">
        <div className="school-info-title right-title school-fees-admin-block-title">
          {t("school.header.study-centers")}
        </div>
        <form onSubmit={handleFormSubmit}>
          <div>
            <div className="school-data-section">
              <div className="money-info-wrapper">
                <div className="reg-fee">
                  <div className="school-data-section-title">
                    {t("school.studyCenter.name")}
                  </div>
                  <input
                    onChange={event => handleChange(event)}
                    name="name"
                    value={studyCenterArray?.name || ""}
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="school-data-section students-number-section">
              <div className="school-data-section-title">
                Picture (recommended size: 240×160)
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {studyCenterArray?.resolvedImageUrl ? (
                  <img
                    src={studyCenterArray?.resolvedImageUrl}
                    style={{ marginBottom: "0" }}
                    height={48}
                    width={48}
                    alt=""
                  />
                ) : (
                  <div className="previewText">
                    {" "}
                    {t("main.userProfileMap.selectImage")}
                  </div>
                )}
                <UpdatePhotoIcon
                  style={{ marginLeft: "10px" }}
                  className="update-photo-icon updatePhoto"
                />
                <div className="update-photo-wrapper-dialog">
                  <CropperPhotoContacts
                    urlUpload={"/schools/files/upload"}
                    name={"imageUrl"}
                    resolveName={"resolvedImageUrl"}
                    changePhoto={changePhoto}
                    aspectRatio={1.5}
                  />
                </div>
              </div>
            </div>
            <div className="school-data-section">
              <div className="school-deposit-block">
                <div className="deposit">
                  <div className="school-data-section-title">
                    {t("school.studyCenter.ageRange")}
                  </div>
                  <input
                    onChange={event => handleChange(event)}
                    name="ageMin"
                    value={studyCenterArray?.ageMin || ""}
                    type="number"
                    required
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "end" }}
                  className="deposit-right"
                >
                  <input
                    onChange={event => handleChange(event)}
                    name="ageMax"
                    value={studyCenterArray?.ageMax || ""}
                    type="number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="school-data-section">
              <div className="money-info-wrapper">
                <div className="reg-fee">
                  <div className="school-data-section-title">
                    {t("school.studyCenter.coordinates")}
                  </div>
                  <input
                    onChange={event => handleChange(event)}
                    name="coordinates"
                    value={studyCenterArray?.coordinates || ""}
                    type="text"
                    required
                  />
                  <p
                    style={{
                      marginBottom: 0,
                      marginTop: "10px",
                      fontFamily: "Alegreya Sans, sans-serif",
                    }}
                  >
                    Can be found on Google maps
                  </p>
                </div>
              </div>
            </div>
            <div className="school-data-section">
              <div className="money-info-wrapper">
                <div className="reg-fee">
                  <div className="school-data-section-title">
                    {t("school.studyCenter.accommodation")}
                  </div>
                  <select
                    id="accommodationId"
                    style={{ width: "300px" }}
                    name="accommodationId"
                    onChange={handleChange}
                    value={studyCenterArray?.accommodationId || ""}
                    className="contactSelect contact-select-change"
                  >
                    <option hidden value="" />

                    {lookupsAccomArray.map((item, idx) => {
                      return (
                        <option key={idx} value={item.id || ""}>
                          {item.value || ""}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="school-data-section">
              <div className="school-deposit-block">
                <div className="deposit">
                  <div className="school-data-section-title">
                    {t("school.studyCenter.childrenPerBedRoom")}
                  </div>
                  <input
                    onChange={event => handleChange(event)}
                    name="childrenPerBedRoomMin"
                    value={studyCenterArray?.childrenPerBedRoomMin || ""}
                    type="number"
                    required
                  />
                </div>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <input
                    onChange={event => handleChange(event)}
                    name="childrenPerBedRoomMax"
                    value={studyCenterArray?.childrenPerBedRoomMax || ""}
                    type="number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="school-data-section school-type-section">
              <div className="school-data-section-title">
                {t("school.studyCenter.courses")}
              </div>

              <div className="data-checkboxes-list">
                {lookupsoursesArray &&
                  lookupsoursesArray.map((item, index) => {
                    return (
                      <div
                        onClick={event => changeCoursesTypes(event, index)}
                        key={index}
                        className="data-checkbox-item"
                      >
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          name="coursesIds"
                          id="coursesIds"
                          onChange={() => {}}
                          value={item.value}
                          checked={item.isChecked}
                        />
                        <label>{item.value}</label>
                      </div>
                    )
                  })}
              </div>
            </div>

            <div className="school-data-section">
              <div>
                <div className="school-data-text-area-section">
                  <div className="school-data-section-title">
                    {t("school.fees.description")}
                  </div>
                  <ReactQuill
                    style={{ marginTop: "15px" }}
                    theme="snow"
                    name="feesContent"
                    value={studyCenterArray?.description || ""}
                    onChange={e => changeEditorData(e, "description")}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="show-all-button save-info-btn students-info-btn school-admin-info-btn w-100"
            type="submit"
          >
            <div>{t("main.userProfileMap.save")}</div>
          </button>
        </form>
      </div>
    </div>
  )
}

export default AdminInfoStudyCenterSection
