import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../../components/CurrentUserContext"
import axios from "../../../http/axios"
import { notification } from "antd"


const AdminInfoOverviewEducationalProgramSection = () => {

  const {t, i18n } = useTranslation()
  const lang = i18n.language
  const isMounted = useRef(null);

  const { currentUser } = useCurrentUser()
  const [educationalArray, setSchoolEducationalArray] = useState([])
  const [allSetting, setAllSetting] = useState({educationalProgramsIds:''})
  const [isSummerSchool, ] = useState(!!currentUser.schoolMode)
  useEffect(async () => {
    isMounted.current = true;

    if (currentUser) {
      await axios.get(`/${isSummerSchool ? 'summer-schools' : 'schools'}/${currentUser.schoolId}/settings` + `?lang=${lang}`).then(async res => {
        if (isMounted) {
          setAllSetting(res.data)
        }

        if (isSummerSchool) {
          await axios.get(`/lookups/summerSchoolEducationalPrograms/lookupresult` + `?lang=${lang}`).then(resp => {
            let auditValue = resp.data?.map(item => {
              item.isChecked = res.data.educationalProgramsIds ? res.data.educationalProgramsIds.includes(item.id) : false
              return item
            })
            if (isMounted) {
              setSchoolEducationalArray(auditValue)
            }
          })
        } else {
          await axios.get(`/educational-programs/lookup` + `?lang=${lang}`).then(resp => {
            let auditValue = resp.data?.map(item => {
              item.isChecked = res.data.educationalProgramsIds ? res.data.educationalProgramsIds.includes(item.id) : false
              return item
            })
            if (isMounted) {
              setSchoolEducationalArray(auditValue)
            }
          })
        }

      })
    }
    return () => {
      isMounted.current = false;
    }
  }, [currentUser])

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  const changeSchoolType = (index) => {
    const arrayType = [...educationalArray]
    arrayType[index]["isChecked"] = !arrayType[index]["isChecked"]
    setSchoolEducationalArray(arrayType)
  }

  function handleFormSubmit (e) {
    e.preventDefault()
    let selectArrayChecked = []
    educationalArray.forEach(item => item.isChecked ? selectArrayChecked.push(item.id) : null)
    axios.post(`/${isSummerSchool ? 'summer-schools' : 'schools'}/${currentUser.schoolId}/settings`, {
      ...allSetting,
      languageCode: lang,
      cabinetSection:"Study programmes",
      educationalProgramsIds: selectArrayChecked
    }).then(()=>{
      openNotification("topRight")
    })
  }
  return (
    <div className="info-overview-wrapper f-js">
      <form onSubmit={handleFormSubmit}>
      <div className="info-overview-content-wrapper">
        <div
          className="school-info-title right-title">{t('school.header.overview')}&nbsp;&nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;&nbsp;&nbsp;{t('school.keydata.edProgrammes')}
        </div>
        <div className="school-data-section select-programmes-section justify-content-center">
          <div className="school-data-section-title">{t('schoolAdmin.selectPrograms')}</div>
          <div className="data-checkboxes-list">

            {educationalArray.map((item, index)=>{
              return(
                <div onClick={() => changeSchoolType( index)} key={item.id} className="data-checkbox-item">
                  <input type="checkbox"
                         className="custom-checkbox"
                         id="black_checkbox"
                         name={allSetting.educationalProgramsIds}
                         value={item.value}
                         onChange={()=>{}}
                         checked={item.isChecked}
                  />
                  <label>{item.value}</label>
                </div>
              )
            })}



          </div>
          <button className="show-all-button save-info-btn students-info-btn school-admin-info-btn" type="submit">
            <div>{t('main.userProfileMap.save')}</div>
          </button>
        </div>
      </div>

      </form>
    </div>

)
}

export default AdminInfoOverviewEducationalProgramSection
