import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../../components/CurrentUserContext"
import axios from "../../../http/axios"
import { notification } from "antd"


const AdminInfoOverviewAdditionalOptions = () => {

  const {t, i18n } = useTranslation()
  const lang = i18n.language
  const isMounted = useRef(null);

  const { currentUser } = useCurrentUser()
  const [schoolAdditionalTypes, setSchoolAdditionalTypes] = useState([])
  const [allSetting, setAllSetting] = useState({educationalProgramsIds:''})
  useEffect(async () => {
    isMounted.current = true;

    if (currentUser) {
      await axios.get(`/summer-schools/${currentUser.schoolId}/settings` + `?lang=${lang}`).then(async res => {
        if (isMounted) {
          setAllSetting(res.data)
        }

        axios
          .get(`/lookups/schoolAdditionalOptions/lookupresult` + `?lang=${lang}`)
          .then(resp => {
            let auditValue = resp.data?.map(item => {
              item.isChecked = res.data.additionalOptionsIds ? res.data.additionalOptionsIds.includes(item.id) : false
              return item
            })
            setSchoolAdditionalTypes(auditValue)
          })


      })
    }
    return () => {
      isMounted.current = false;
    }
  }, [currentUser])


  const changeAdditionalTypes = (e, index) => {
    const arrayType = [...schoolAdditionalTypes]
    arrayType[index]["isChecked"] = !arrayType[index]["isChecked"]
    setSchoolAdditionalTypes(arrayType)
  }


  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }


  function handleFormSubmit (e) {
    e.preventDefault()
    let selectAdditionalOptionsChecked = []
    schoolAdditionalTypes.forEach(item =>
      item.isChecked ? selectAdditionalOptionsChecked.push(item.id) : null
    )
    axios.post(`/summer-schools/${currentUser.schoolId}/settings`, {
      ...allSetting,
      additionalOptionsIds: selectAdditionalOptionsChecked
    }).then(()=>{
      openNotification("topRight")
    })
  }
  return (
    <div className="info-overview-wrapper f-js">
      <form onSubmit={handleFormSubmit}>
        <div className="info-overview-content-wrapper">
          <div
            className="school-info-title right-title">{t('school.header.overview')}&nbsp;&nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;&nbsp;&nbsp;{t('school.keydata.adOptions')}
          </div>
          <div className="school-data-section select-programmes-section justify-content-center">
            <div className="school-data-section-title">{t('schoolAdmin.selectPrograms')}</div>
            <div className="data-checkboxes-list">

              {schoolAdditionalTypes.map((item, index)=>{
                return(
                  <div onClick={(e) => changeAdditionalTypes( e, index)} key={item.id} className="data-checkbox-item">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      name="additionalOptionsIds"
                      id="additionalOptionsIds"
                      onChange={() => {
                      }}
                      value={item.value}
                      checked={item.isChecked}
                    />
                    <label>{item.value}</label>
                  </div>
                )
              })}
            </div>
            <button className="show-all-button save-info-btn students-info-btn school-admin-info-btn" type="submit">
              <div>{t('main.userProfileMap.save')}</div>
            </button>
          </div>
        </div>

      </form>
    </div>

  )
}

export default AdminInfoOverviewAdditionalOptions
