import React from "react";
import { useTranslation } from "react-i18next";
import CheckedIcon from "../../svg/check.inline.svg";
import Table from "../../components/Table"

const SchoolItemSportsSection = (props) => {
  const sports = props.sports || []

  const sportPaymentFormats = (props.sportPaymentFormats || []).map((el, i) => {
    if (i === 0) {
      el.cssClass = 'red-dot'
    } else if (i === 1) {
      el.cssClass = 'dark-dot'
    } else {
      el.cssClass = 'double-dots'
    }
    return el;
  })

  const arr = [];
  sports.forEach(el => {
    const existing = arr.find(x => x.id === el.activityId);
    if (existing) {
      existing.items.push({
        certId: el.certificateId,
        paymentId: el.paymentFormatId
      })
    } else {
      const obj = {
        id: el.activityId,
        name: el.activityName,
        items: [{
          certId: el.certificateId,
          paymentId: el.paymentFormatId
        }]
      }
      arr.push(obj);
    }
  })

  let redundantSports = []
  sports.forEach(sport => {
    if (!sport.paymentFormatIds && props.isSchoolPage) {
      redundantSports.push(sport.value)
    }
  })

  let allUsedFormats = [...new Set(sports
    .map(sport => {
      if (sport.paymentFormatIds && props.isSchoolPage) {
        return (sport.paymentFormatIds)
      }
    })
    .flat(Infinity))]
    .filter(element => {
      return element !== undefined;
    })



  let trimmedFormats = []
  if (allUsedFormats.length > 6) {
    trimmedFormats = allUsedFormats.slice(6, allUsedFormats.length)
    allUsedFormats = allUsedFormats.slice(0, 6)
  }



  function changeChecked(subj, cert, index) {
    if (props.changeChecked) {
      props.changeChecked(subj, cert, index)
    }
  }
  const { t } = useTranslation();

  return (
    <>
      {props.isSchoolPage ?
        <div className="white-block about-school-block">
          <div className={`${!props.style ? 'about-school-content-wrapper' : null} f-js container`}>
            <div className=" about-school-info">
              <div className="table-legend-wrapper f-jb f-ae">
                <div className="key-data-title school-info-title">{t('school.sports.sportsTitle')}</div>
              </div>
              {/* <!---- SUBJECTS TABLE----> */}
              <table className="school-item-table">
                <thead>
                  <tr>
                    <th className="empty-section"></th>
                    {
                      sportPaymentFormats.map(c => (
                        <>
                          {allUsedFormats.includes(c.id) &&
                            <th key={c.id}>
                              <div className="school-comparison-item-wrapper">
                                <div className="school-comparison-item-title">
                                  {c.value}
                                </div>
                              </div>
                            </th>
                          }
                        </>
                      ))
                    }
                    {trimmedFormats.length > 0 &&
                      <th>
                        <div className="school-comparison-item-wrapper">
                          <div className="school-comparison-item-title">
                            {t("school.subjects.other")}
                          </div>
                        </div>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    sports
                      .sort((a, b) => a.value.localeCompare(b.value))
                      .map((el, index) => (
                        <>
                          {
                            (!redundantSports.includes(el.value)) &&
                            <tr key={el.activityId}>
                              <td className="row-title ">{el.value}</td>
                              {
                                allUsedFormats.map(c => {
                                  const isChecked = (el.paymentFormatIds || []).includes(c);
                                  return (
                                    <td onClick={() => changeChecked(el, c.id, index)} key={c}>
                                      <CheckedIcon className={`${isChecked ? 'checked-icon' : 'not-checked'}`} />
                                    </td>
                                  )
                                })
                              }
                            </tr>
                          }
                        </>
                      ))
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>
        : <div
          className={`white-block  ${!props.style ? "about-school-block" : null}`}
        >
          <div
            className={`${!props.style
              ? "about-school-content-wrapper"
              : "about-school-content-wrapper-prof"
              } f-js container`}
          >
            <div className=" about-school-info">
              <div className="key-data-title school-info-title">
                {t("school.subjects.subjectsTitle")}
              </div>
              {/* <!---- SUBJECTS TABLE----> */}
              <div className="container mt-4">
                <Table headers={sportPaymentFormats} data={sports} changeChecked={changeChecked} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default SchoolItemSportsSection
