import React, { useEffect, useState } from "react"
import { useCurrentUser } from "../../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import axios from "../../../http/axios"
import SchoolItemSportsSection from "../../schools/SchoolItemSportsSection"

const AdminInfoSportsSection = () => {
  const { currentUser } = useCurrentUser()
  const { i18n } = useTranslation()
  const lang = i18n.language
  const [rowsArray, setRowsArray] = useState([])
  const [colsArray, seColsArray] = useState([])

  useEffect(async () => {
    if (currentUser) {
      await axios
        .get(`/schools/${currentUser.schoolId}/activities` + `?lang=${lang}`)
        .then(res => {
          setRowsArray(res.data)
        })
        .catch()
    }
    await axios
      .get(
        `/lookups/schoolActivitiesPaymentFormats/lookupresult` + `?lang=${lang}`
      )
      .then(res => {
        seColsArray(res.data)
      })
      .catch()
  }, [currentUser])

  const onUncheck = (body, cert) => {
    const selectSubj = {
      schoolActivities: [{ ...body, paymentFormatId: cert }],
    }
    axios
      .post(`/schools/${currentUser.schoolId}/activities/batch-delete`, {
        ...selectSubj,
        cabinetSection: "Sports & activities",
      })
      .then()
      .catch()
  }

  const onCheck = (body, cert) => {
    const selectSubj = {
      schoolActivities: [{ ...body, paymentFormatId: cert }],
    }
    axios
      .post(`/schools/${currentUser.schoolId}/activities/batch-save`, {
        ...selectSubj,
        cabinetSection: "Sports & activities",
      })
      .then()
      .catch()
  }

  const changeChecked = (subj, col, index) => {
    const subjectIds = subj.paymentFormatIds ? subj.paymentFormatIds : []
    let changeSelect
    if (!subjectIds.includes(col)) {
      onCheck(subj, col)
      changeSelect = { ...subj, paymentFormatIds: [...subjectIds, col] }
    } else
      onUncheck(subj, col),
        (changeSelect = {
          ...subj,
          paymentFormatIds: subjectIds.filter(item => item !== col),
        })

    const list = [...rowsArray]
    list[index] = changeSelect
    setRowsArray(list)
  }

  return (
    <div className="info-overview-wrapper f-js subjects-table-block school-admin-subjects-table">
      <SchoolItemSportsSection
        sports={rowsArray}
        sportPaymentFormats={colsArray}
        changeChecked={changeChecked}
        style="white"
      ></SchoolItemSportsSection>
    </div>
  )
}
export default AdminInfoSportsSection
