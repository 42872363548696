import React, { useEffect, useRef, useState } from "react"
import { useCurrentUser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import axios from "../../http/axios"
import { notification } from "antd"
import WhiteDeleteIcon from "../../svg/white-delete.inline.svg"
import DeleteIcon from "../../svg/delete-icon.inline.svg"


const AdminInfoGallerySection = () => {
  const { currentUser } = useCurrentUser()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [galleryArray, setGalleryArray] = useState([])
  const isMounted = useRef(null);
  const [linkValue, setLinkValue] = useState("")
  const [isSummerSchool,] = useState(!!currentUser.schoolMode)
  const videoExtensions = ["mp3", "mp4", "webm", "mkv", "flv", "vob", "ogv", "drc", "gif", "gifv",
    "mng", "avi", "MTS", "M2TS", "TS", "mov", "qt", "wmv", "yuv", "rm", "rmvb", "viv",
    "asf", "amv", "m4p", "m4v", "mpg", "mp2", "mpeg", "mpe", "mpv", "mpg", "mpeg", "m2v",
    "m4v", "svi", "3gp", "3g2", "mxf", "roq", "nsv", "flv", "f4v", "f4p", "f4a", "f4b"
  ]

  const imageExtensions = ["bmb", "gif", "jpg", "png", "tiff"]

  useEffect(async () => {
    isMounted.current = true;
    if (currentUser) {
      await axios.get(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}/media` + `?lang=${lang}`).then(res => {
        setGalleryArray(res.data)
      })
    }
    return () => {
      isMounted.current = false;
    }
  }, [currentUser])

  const fileUpload = (e) => {
    const url = `/schools/files/upload`
    const formData = new FormData()
    formData.append("file", e.target.files[0])
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    axios.post(url, formData, config).then((response) => {
      setGalleryArray(prevState => ([
        ...prevState,
        {
          resolvedFilePath: response.data.resolvedFilePath,
          contentType: e.target.files[0].type,
          filePath: response.data.filePath
        }
      ]))
    })
  }
  const checkIsvideo = (currentUrl) => {
    const ext = currentUrl?.split(".") ? currentUrl?.split(".").pop() : currentUrl
    return videoExtensions.includes(ext.toLowerCase())
  }


  function checkImage(currentUrl) {
    const ext = currentUrl?.split(".") ? currentUrl?.split(".").pop() : currentUrl
    return imageExtensions.includes(ext.toLowerCase())
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
    const list = [...galleryArray]
    list[index][name] = value
    list[index]["languageCode"] = lang
    setGalleryArray(list)
  }

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement
    })
  }

  const sendNewLink = (e, nameClick) => {
    let valueSend

    if (e.target.value) {
      valueSend = e.target.value
      setLinkValue(valueSend)
    }

    if (nameClick === "send") {
      setGalleryArray(prevState => ([
        ...prevState,
        { filePath: linkValue, resolvedFilePath: linkValue }
      ]))
      if (document.getElementById("linkId")) {
        document.getElementById("linkId").value = ""
      }
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    axios.post(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}/media/list`, {
      "mediaList": galleryArray, "languageCode": lang, "cabinetSection": "Gallery"
    }).then(res => {
      openNotification("topRight")
    })

  }

  const handleRemoveItem = (itemId, index) => {
    let array = [...galleryArray]
    let resultArray = array.filter((_, i) => i !== index)
    setGalleryArray(resultArray)
  }

  return (
    <>
      <div className="info-overview-wrapper f-js overview-location" style={{ paddingBottom: "40px" }}>
        <div className="info-overview-content-wrapper">
          <div className="school-info-title right-title">{t("school.header.gallery")}</div>
          <div className="gallery-images-wrapper">
            <div className="school-data-section-title">{t("schoolAdmin.addMedia")}</div>
            <div className="drop-zone">
              <div className="position-image">
                <button className="selectImage update-photo drop-zone__prompt" />
                <div className="image-cover">
                  <input className="drop-zone__input fileGalleryInput"

                    onChange={(e) => fileUpload(e)}
                    name="fname"
                    type="file"
                    id="files"
                  />
                </div>
              </div>
              <div className="media-link">
                <div style={{ marginBottom: "14px" }}
                  className="school-data-section-title">{t("schoolAdmin.link")}</div>
                <div className="d-flex w-100 flex-column align-items-center justify-content-center gap-3">
                  <input onChange={(e) => sendNewLink(e, null)}
                    className="w-100" type="text" id="linkId" name="link" style={{ height: "30px" }} />

                  <button style={{ width: "30%" }} onClick={(e) => sendNewLink(e, "send")} className="py-3 px-4 show-all-button"
                    type="button">
                    <div>{t("main.userProfileMap.add")}</div>
                  </button>
                </div>

              </div>
            </div>
            <form onSubmit={handleFormSubmit} className="d-flex flex-column justify-content-center container">

              <div className="row">
                {galleryArray &&
                  galleryArray.map((item, index) => {
                    return (
                      <div key={item.id}
                        className="d-flex mt-3 flex-column justify-content-between col-sm-12 col-md-6 col-xxl-3 position-relative">


                        {!checkIsvideo(item.resolvedFilePath) && !checkImage(item.resolvedFilePath)
                          ?
                          <DeleteIcon style={{
                            width: "20px",
                            height: "20px",
                            position: "absolute",
                            right: "14px",
                            top: "12px",
                            zIndex: "25"
                          }}
                            onClick={(e) => handleRemoveItem(e, index)}
                            className="delete-icon" />
                          :
                          <WhiteDeleteIcon style={{
                            width: "30px",
                            height: "25px",
                            position: "absolute",
                            right: "14px",
                            top: "12px",
                            zIndex: "25"
                          }}
                            onClick={(e) => handleRemoveItem(e, index)}
                            className="delete-icon" />}




                        {checkIsvideo(item.resolvedFilePath) ?
                          <div className="position-relative">
                            <video
                              // width="200" height="200"
                              className="w-100"
                              style={{ minHeight: "200px" }}
                              controls
                              data-config="some-js-object-here"
                              src={item.resolvedFilePath}
                            >
                            </video>
                            <div className="black-background w-100 position-absolute"
                              style={{
                                top: "0",
                                background: "linear-gradient(180deg, #091034 0%, rgba(9, 16, 52, 0) 100%)",
                                height: "50px"
                              }}
                            >

                            </div>
                          </div>
                          :
                          <div className="d-flex justify-content-center h-100">
                            {checkImage(item.resolvedFilePath) ?
                              <div style={{
                                width: "100%",
                                minHeight: "200px",
                                backgroundImage:
                                  `linear-gradient(rgba(11, 16, 32, 0.3) 0%, rgba(11, 16, 32, 0.3) 100%), url(${item.resolvedFilePath})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center"

                              }}>

                              </div>

                              :
                              <div className="position-relative w-100 d-flex justify-content-center align-items-center">
                                <a className="w-100"
                                  href={item.resolvedFilePath}>{item.resolvedFilePath}</a>
                                {/* <div className="black-background w-100 position-absolute"
                                  style={{
                                    top: "0",
                                    background: "linear-gradient(180deg, #091034 0%, rgba(9, 16, 52, 0) 100%)",
                                    height: "50px"
                                  }}
                                ></div> */}
                              </div>

                            }
                          </div>


                        }
                        <div className="d-flex flex-column gap-3 mt-3">
                          <div className="d-flex flex-column gap-2">
                            <label>{t("main.header.position")}</label>
                            <input onChange={(event) => handleChange(event, index)} value={item.position}
                              className="w-100" type="number" name="position"
                              required />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <label>{t("school.fees.description")}</label>

                            <textarea style={{ padding: "10px", border: "2px solid rgba(0, 0, 0, 0.2)", minHeight: "100px" }} onChange={(event) => handleChange(event, index)} name="description"
                              value={item.description || ""}
                              className="w-100">{item.description}</textarea>
                          </div>
                        </div>

                      </div>
                    )
                  })
                }
              </div>
              <div className="w-100 d-flex justify-content-center">
                <button className="w-100 show-all-button save-info-btn students-info-btn school-admin-info-btn" type="submit">
                  <div>{t("main.userProfileMap.save")}</div>
                </button>
              </div>

            </form>

          </div>
        </div>
      </div>


    </>
  )
}
export default AdminInfoGallerySection
