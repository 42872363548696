import React, { useEffect, useRef, useState } from "react"
import PlusIcon from "../../../svg/plus-icon.inline.svg"
import axios from "../../../http/axios"
import { isBrowser, useCurrentUser } from "../../../components/CurrentUserContext"
import DeleteIcon from "../../../svg/delete-icon.inline.svg"
import { useTranslation } from "react-i18next"
import { notification } from "antd"
import moment from "moment"
let ReactQuill = null
if (isBrowser()) {
  ReactQuill = require("react-quill")
}

const AdminInfoFeesCurrentYear = ({ props, setChange }) => {
  const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

  const { currentUser } = useCurrentUser()
  const [feesArray, setFeesArray] = useState({})
  const { t, i18n } = useTranslation()
  const [isSummerSchool, ] = useState(!!currentUser.schoolMode)
  const currentLang = i18n.language
  const isMounted = useRef(null);

  useEffect(async () => {
    isMounted.current = true;
    if (isMounted){
      setFeesArray(props)
    }

    return () => {
      isMounted.current = false;
    }
  }, [props])

  const handleChange = e => {
    const { name, value } = e.target
    setFeesArray(prevState => ({
      ...prevState,
      [name]: value
    }))
  }


  const changeEditorData = (event, name) => {
    setFeesArray(prevState => ({
      ...prevState,
      [name]: event
    }))
  }
  const handleChangeFeesGroup = (e, index) => {
    const { name, value } = e.target
    if (isSummerSchool) {
      const list = [...feesArray.summerSchoolFeeCourses]
      list[index][name] = value
      setFeesArray(prevState => ({
        ...prevState, ["summerSchoolFeeCourses"]: list
      }))
    } else {
      const list = [...feesArray?.feeGroups]
      list[index][name] = value
      setFeesArray(prevState => ({
        ...prevState, ["feeGroups"]: list
      }))
    }

  }


  const handleAddItem = () => {
    if (isSummerSchool) {

      if(feesArray && feesArray.summerSchoolFeeCourses){
        setFeesArray(prevState => ({
          ...prevState,
          ["summerSchoolFeeCourses"]: [...prevState?.summerSchoolFeeCourses, {
            startDate: "",
            endDate: "",
            courseName: "",
            description: "",
            fees: ""
          }]
        }))
      } else {
        setFeesArray(prevState => ({
          ...prevState,
          ["summerSchoolFeeCourses"]: [{
            startDate: "",
            endDate: "",
            courseName: "",
            description: "",
            fees: ""
          }]
        }))
      }




    } else {
      setFeesArray(prevState => ({
        ...prevState, ["feeGroups"]: [...prevState.feeGroups, { yearGroup: "", feePerTermAmount: "" }]
      }))
    }

  }

  const handleRemoveItem = (itemId, index) => {

    if (isSummerSchool) {
      let array = [...feesArray.summerSchoolFeeCourses]
      let resultArray = array.filter((_, i) => i !== index)
      setFeesArray(prevState => ({
        ...prevState, ["summerSchoolFeeCourses"]: resultArray
      }))
    } else {
      let array = [...feesArray?.feeGroups]
      let resultArray = array.filter((_, i) => i !== index)
      setFeesArray(prevState => ({
        ...prevState, ["feeGroups"]: resultArray
      }))
    }


  }

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement
    })
  }

  function handleFormSubmit(e) {
    e.preventDefault()

    feesArray.languageCode = currentLang

    axios.post(`/${isSummerSchool ? "summer-schools" : "schools"}/${currentUser.schoolId}/fees`, {
      ...feesArray, weeklyFeeMin: Number(feesArray.weeklyFeeMin),weeklyFeeMax: Number(feesArray.weeklyFeeMax),registrationFee: Number(feesArray.registrationFee),
      cabinetSection: "Fees"
    }).then(() => {
      setChange('newFees')
      openNotification("topRight")
    }).catch((e) => {
      notification.error({
        message: e.response.data.message,
        placement: "topRight",
      })
    })
  }


  return (
    <div className="info-overview-wrapper f-js fees-block overview-location">
      <div className="info-overview-content-wrapper">
        <div className="school-info-title right-title school-fees-admin-block-title">
          {t("school.header.fees")}
          {/* &nbsp;&nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;&nbsp;&nbsp;{t('schoolAdmin.currYear')} */}
        </div>
        <form onSubmit={handleFormSubmit}>
          <div>

            <div className="school-data-section">
              <div className="money-info-wrapper">
                <div className="reg-fee">
                  <div className="school-data-section-title">{t("school.fees.regFee")}</div>
                  <input onChange={(event) => handleChange(event)} name="registrationFee"
                         value={feesArray?.registrationFee || ""} type="number"/>
                </div>
              </div>
            </div>

            {!isSummerSchool && <div className="school-data-section">
              <div className="school-deposit-block">
                <div className="deposit">
                  <div className="school-data-section-title">{t("schoolAdmin.depositMin")}</div>
                  <input onChange={(event) => handleChange(event)} name="depositMin"
                         value={feesArray?.depositMin || ""} type="number" required/>
                </div>
                <div className="deposit-right">
                  <div className="school-data-section-title">{t("schoolAdmin.depositMax")}</div>
                  <input onChange={(event) => handleChange(event)} name="depositMax"
                         value={feesArray?.depositMax || ""} type="number" required/>
                </div>
              </div>
            </div>}

            {!isSummerSchool && <div className="school-data-section">
              <div className="school-deposit-block">
                <div className="deposit">
                  <div className="school-data-section-title">{t("schoolAdmin.termMin")}</div>
                  <input onChange={(event) => handleChange(event)} name="perTermMin"
                         value={feesArray?.perTermMin || ""} type="number" required/>
                </div>
                <div className="deposit-right">
                  <div className="school-data-section-title">{t("schoolAdmin.termMax")}</div>
                  <input onChange={(event) => handleChange(event)} name="perTermMax"
                         value={feesArray?.perTermMax || ""} type="number" required/>
                </div>
              </div>
            </div>}
            {isSummerSchool && <div className="school-data-section">
              <div className="school-deposit-block">
                <div className="deposit">
                  <div className="school-data-section-title">{t("schoolAdmin.weeklyFeeMin")}</div>
                  <input onChange={(event) => handleChange(event)} name="weeklyFeeMin"
                         value={feesArray?.weeklyFeeMin || ""} type="number" required/>
                </div>
                <div className="deposit-right">
                  <div className="school-data-section-title">{t("schoolAdmin.weeklyFeeMax")}</div>
                  <input onChange={(event) => handleChange(event)} name="weeklyFeeMax"
                         value={feesArray?.weeklyFeeMax || ""} type="number" required/>
                </div>
              </div>
            </div>}


            {!isSummerSchool && <div className="school-data-section fees-per-term">
              <div className="groups-fees-wrapper f-js">
                <div className="payments-part f-js">
                  <div className="reg-fee">
                    <div style={{ display: "flex" }}>
                      <div className="school-data-section-title">{t("school.fees.yearGroup")}</div>
                      <div style={{ paddingLeft: "100px" }}
                           className="school-data-section-title">{t("school.fees.fullFee")}
                      </div>
                    </div>

                    {feesArray && feesArray?.feeGroups?.map((itemGroup, index) => {
                      return (
                        <div key={itemGroup.id} className="select-group">
                          <div className="select-box__current">
                            <input onChange={(e) => handleChangeFeesGroup(e, index)} name="yearGroup"
                                   value={itemGroup?.yearGroup || ""} type="number" />

                          </div>
                          <div className="select-box__current select-box__current-right">
                            <input onChange={(e) => handleChangeFeesGroup(e, index)} name="feePerTermAmount"
                                   value={itemGroup?.feePerTermAmount || ""} type="number" />
                            <DeleteIcon onClick={(e) => handleRemoveItem(e, index)}
                                        className="delete-icon delete-icon-fees" />

                          </div>

                        </div>
                      )
                    })}
                  </div>
                </div>

                <div onClick={handleAddItem} className="add-child-wrapper f-js f-ac p-20 curs-P">
                  <PlusIcon className="plus-icon" />
                  <div className="user-nav-bar-option active">{t("schoolAdmin.addClass")}</div>
                </div>
              </div>
            </div>}

            {isSummerSchool && <div className="school-data-section fees-per-term">
              <div className="groups-fees-wrapper f-js">
                <div className="payments-part f-js">
                  <div className="reg-fee">
                    {feesArray && feesArray?.summerSchoolFeeCourses?.map((itemGroup, index) => {
                      return (
                        <div style={{ flexWrap: "wrap" }} key={itemGroup.id} className="select-group">

                          <div style={{ width: "218px" }} className="select-box__current">
                            <div style={{ height: "34px" }}
                                 className="select-box__current">{t("school.fees.startDate")}</div>

                            <input
                              type="date"
                              name="startDate"
                              value={moment(itemGroup.startDate || "").format("YYYY-MM-DD")}
                              onChange={e => handleChangeFeesGroup(e, index)}
                            />
                          </div>
                          <div style={{ width: "218px" }} className="select-box__current">
                            <div style={{ height: "34px", width: "180px" }}
                                 className="select-box__current">{t("school.fees.endDate")}</div>

                            <input
                              type="date"
                              name="endDate"
                              value={moment(itemGroup.endDate || "").format("YYYY-MM-DD")}
                              onChange={e => handleChangeFeesGroup(e, index)}
                            />
                          </div>

                          <div className="select-box__current">
                            <div style={{ height: "34px" }}
                                 className="select-box__current">{t("school.fees.courseName")}</div>

                            <input onChange={(e) => handleChangeFeesGroup(e, index)} name="courseName"
                                   value={itemGroup?.courseName || ""} type="text" />
                          </div>
                          <div className="select-box__current">
                            <div style={{ height: "34px" }}
                                 className="select-box__current">{t("school.fees.description")}</div>
                            <input onChange={(e) => handleChangeFeesGroup(e, index)} name="description"
                                   value={itemGroup?.description || ""} type="text" />
                          </div>

                          <div className="select-box__current">
                            <div style={{ height: "34px" }}
                                 className="select-box__current">{t("school.fees.fees")}</div>
                            <input onChange={(e) => handleChangeFeesGroup(e, index)} name="fees" value={itemGroup?.fees}
                                   type="number" />
                            <DeleteIcon onClick={(e) => handleRemoveItem(e, index)}
                                        className="delete-icon delete-icon-fees" />

                          </div>

                        </div>
                      )
                    })}
                  </div>
                </div>

                <div onClick={handleAddItem} className="add-child-wrapper f-js f-ac p-20 curs-P">
                  <PlusIcon className="plus-icon" />
                  <div className="user-nav-bar-option active">{t("schoolAdmin.addClass")}</div>
                </div>
              </div>
            </div>}

            <div className="school-data-section">
              <div>


                <div className="school-data-text-area-section">
                  <div className="school-data-section-title">{t("school.fees.feeIncludes")}</div>
                  <ReactQuill
                    style={{ marginTop: "15px" }}
                    theme="snow"
                    name="feesContent"
                    value={feesArray?.feesContent || ""}
                    onChange={(e) => changeEditorData(e, "feesContent")}
                  />
                </div>

                {!isSummerSchool && <div className="school-data-text-area-section">
                  <div className="school-data-section-title">{t("school.fees.discounts")}</div>
                  <ReactQuill
                    style={{ marginTop: "15px" }}
                    theme="snow"
                    name="discounts"
                    value={feesArray?.discounts || ""}
                    onChange={(e) => changeEditorData(e, "discounts")}
                  />
                </div>}

                {!isSummerSchool && <div className="school-data-text-area-section">
                  <div className="school-data-section-title">{t("school.fees.languageTuition")}</div>
                  <ReactQuill
                    style={{ marginTop: "15px" }}
                    theme="snow"
                    name="teachingEnglish"
                    value={feesArray?.teachingEnglish || ""}
                    onChange={(e) => changeEditorData(e, "teachingEnglish")}
                  />
                </div>}

                <div>
                  {isSummerSchool ?
                    <div className="school-data-section-title">{t("school.fees.otherFeesSummer")}</div> :
                    <div className="school-data-section-title">{t("school.fees.otherFees")}</div>}
                  <ReactQuill
                    style={{ marginTop: "15px" }}
                    theme="snow"
                    name="feesOther"
                    value={feesArray?.feesOther || ""}
                    onChange={(e) => changeEditorData(e, "feesOther")}
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="show-all-button save-info-btn students-info-btn school-admin-info-btn w-100" type="submit">
            <div>{t("main.userProfileMap.save")}</div>
          </button>
        </form>
      </div>
    </div>

  )
}

export default AdminInfoFeesCurrentYear
