import React from "react"

import AdminDashboardHeaderSection from "../../sections/admin_profile_sections/AdminDashboardHeaderSection"
import AdminInfoSection from "../../sections/admin_profile_sections/AdminInfoSection"

import { useCurrentUser } from "../../components/CurrentUserContext"

import AccessDeniedMessage from "../../components/AccessDeniedMessage"

import Layout from "../../components/Layout"

const AdminInfoAbout = () => {
  const { currentUser, userLoading } = useCurrentUser()

  return (
    <Layout isSecondLayout={true}>
      {currentUser && currentUser.accountType === "school" ? (
        <div className="global-wrapper user-saved school-admin-global-wrapper">
          <AdminDashboardHeaderSection
            state2="active"
            choice_status2="chosen"
          />
          <AdminInfoSection />
        </div>
      ) : (
        <AccessDeniedMessage
          currentUser={currentUser}
          userLoading={userLoading}
        />
      )}
    </Layout>
  )
}

export default AdminInfoAbout
