import React, { useEffect, useRef, useState } from "react"
import axios from "../../http/axios"
import { useCurrentUser, isBrowser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"

let ReactQuill = null
if (isBrowser()) {
  ReactQuill = require("react-quill")
}

// import "react-quill/dist/quill.snow.css"
import { notification } from "antd"

const AdminInfoAboutSection = () => {
  const { currentUser } = useCurrentUser()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [aboutArray, setAboutArray] = useState([])
  const [isSummerSchool] = useState(!!currentUser.schoolMode)
  const isMounted = useRef(null)

  useEffect(async () => {
    isMounted.current = true

    if (currentUser) {
      await axios
        .get(
          `/${isSummerSchool ? "summer-schools" : "schools"}/${
            currentUser.schoolId
          }` + `?lang=${lang}`
        )
        .then(res => {
          setAboutArray(res.data)
        })
    }
    return () => {
      isMounted.current = false
    }
  }, [currentUser])

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  function handleFormSubmit(e) {
    e.preventDefault()

    let resultItem

    if (isSummerSchool) {
      resultItem = {
        aboutCompany: aboutArray?.aboutCompany,
        tuition: aboutArray?.tuition,
        boarding: aboutArray?.boarding,
        activities: aboutArray?.activities,
        languageCode: lang,
      }
    } else {
      resultItem = {
        uniqueSellingPoints: aboutArray?.uniqueSellingPoints,
        history: aboutArray?.history,
        location: aboutArray?.location,
        teaching: aboutArray?.teaching,
        boarding: aboutArray?.boarding,
        activities: aboutArray?.activities,
        languageCode: lang,
      }
    }

    axios
      .put(
        `/${isSummerSchool ? "summer-schools" : "schools"}/${
          currentUser.schoolId
        }`,
        {
          ...aboutArray,
          ...resultItem,
          cabinetSection: "About",
        }
      )
      .then(() => {
        openNotification("topRight")
      })
  }

  const handleChange = (e, index) => {
    if (isSummerSchool) {
      switch (index) {
        case 0:
          aboutArray.aboutCompany = e
          break
        case 1:
          aboutArray.tuition = e
          break
        case 2:
          aboutArray.boarding = e
          break
        case 3:
          aboutArray.activities = e
          break
        default:
      }
    } else {
      switch (index) {
        case 0:
          aboutArray.uniqueSellingPoints = e
          break
        case 1:
          aboutArray.history = e
          break
        case 2:
          aboutArray.location = e
          break
        case 3:
          aboutArray.teaching = e
          break
        case 4:
          aboutArray.boarding = e
          break
        case 5:
          aboutArray.activities = e
          break
        default:
      }
    }
  }

  return (
    <>
      <div>
        {aboutArray && (
          <div className="info-overview-wrapper f-js px-4">
            <div className="info-overview-content-wrapper">
              <div className="school-info-title right-title">
                {t("school.header.about")}
              </div>

              <div className="school-text-areas-wrapper">
                <div
                  className="school-data-text-area-section"
                  style={{ marginBottom: "40px" }}
                >
                  <form onSubmit={handleFormSubmit}>
                    {!isSummerSchool && (
                      <div className="school-data-text-area-section">
                        <div className="school-data-section-title">
                          {t("school.about.sellingPoints")}
                        </div>
                        {isBrowser() ? (
                          <ReactQuill
                            theme="snow"
                            value={aboutArray?.uniqueSellingPoints || ""}
                            onChange={e => handleChange(e, 0)}
                          />
                        ) : (
                          <textarea
                            onChange={e => handleChange(e, 0)}
                            name="uniqueSellingPoints"
                            value={aboutArray?.uniqueSellingPoints || ""}
                            className="school-info-textarea"
                          >
                            {aboutArray?.uniqueSellingPoints || ""}
                          </textarea>
                        )}
                      </div>
                    )}
                    {!isSummerSchool ? (
                      <div className="school-data-text-area-section">
                        <div className="school-data-section-title">
                          {t("school.about.history")}
                        </div>
                        {isBrowser() ? (
                          <ReactQuill
                            theme="snow"
                            value={aboutArray?.history || ""}
                            onChange={e => handleChange(e, 1)}
                          />
                        ) : (
                          <textarea
                            onChange={e => handleChange(e, 1)}
                            name="history"
                            value={aboutArray?.history || ""}
                            className="school-info-textarea"
                          >
                            {aboutArray?.history || ""}
                          </textarea>
                        )}
                      </div>
                    ) : null}
                    {!isSummerSchool ? (
                      <div className="school-data-text-area-section">
                        <div className="school-data-section-title">
                          {t("school.about.location")}
                        </div>
                        {isBrowser() ? (
                          <ReactQuill
                            theme="snow"
                            value={aboutArray?.location || ""}
                            onChange={e => handleChange(e, 2)}
                          />
                        ) : (
                          <textarea
                            onChange={e => handleChange(e, 2)}
                            name="location"
                            value={aboutArray?.location || ""}
                            className="school-info-textarea"
                          >
                            {aboutArray.location || ""}
                          </textarea>
                        )}
                      </div>
                    ) : null}
                    {!isSummerSchool ? (
                      <div className="school-data-text-area-section">
                        <div className="school-data-section-title">
                          {t("school.about.teaching")}
                        </div>
                        {isBrowser() ? (
                          <ReactQuill
                            theme="snow"
                            value={aboutArray?.teaching || ""}
                            onChange={e => handleChange(e, 3)}
                          />
                        ) : (
                          <textarea
                            onChange={e => handleChange(e, 3)}
                            name="teaching"
                            value={aboutArray?.teaching || ""}
                            className="school-info-textarea"
                          >
                            {aboutArray?.teaching || ""}
                          </textarea>
                        )}
                      </div>
                    ) : null}

                    {isSummerSchool ? (
                      <div className="school-data-text-area-section">
                        <div className="school-data-section-title">
                          {t("school.about.aboutCompany")}
                        </div>
                        {isBrowser() ? (
                          <ReactQuill
                            theme="snow"
                            value={aboutArray?.aboutCompany || ""}
                            onChange={e => handleChange(e, 0)}
                          />
                        ) : (
                          <textarea
                            onChange={e => handleChange(e, 0)}
                            name="aboutCompany"
                            value={aboutArray?.aboutCompany || ""}
                            className="school-info-textarea"
                          >
                            {aboutArray?.aboutCompany || ""}
                          </textarea>
                        )}
                      </div>
                    ) : null}
                    {isSummerSchool ? (
                      <div className="school-data-text-area-section">
                        <div className="school-data-section-title">
                          {t("school.about.tuition")}
                        </div>
                        {isBrowser() ? (
                          <ReactQuill
                            theme="snow"
                            value={aboutArray?.tuition || ""}
                            onChange={e => handleChange(e, 1)}
                          />
                        ) : (
                          <textarea
                            onChange={e => handleChange(e, 1)}
                            name="tuition"
                            value={aboutArray?.tuition || ""}
                            className="school-info-textarea"
                          >
                            {aboutArray?.tuition || ""}
                          </textarea>
                        )}
                      </div>
                    ) : null}

                    <div className="school-data-text-area-section">
                      <div className="school-data-section-title">
                        {t("school.about.boarding")}
                      </div>
                      {isBrowser() ? (
                        <ReactQuill
                          theme="snow"
                          value={aboutArray?.boarding || ""}
                          onChange={e =>
                            handleChange(e, isSummerSchool ? 2 : 4)
                          }
                        />
                      ) : (
                        <textarea
                          onChange={e =>
                            handleChange(e, isSummerSchool ? 2 : 4)
                          }
                          name="boarding"
                          value={aboutArray?.boarding || ""}
                          className="school-info-textarea"
                        >
                          {aboutArray?.boarding || ""}
                        </textarea>
                      )}
                    </div>
                    <div className="school-data-text-area-section">
                      <div className="school-data-section-title">
                        {t("school.about.sports")}
                      </div>
                      {isBrowser() ? (
                        <ReactQuill
                          theme="snow"
                          value={aboutArray?.activities || ""}
                          onChange={e =>
                            handleChange(e, isSummerSchool ? 3 : 5)
                          }
                        />
                      ) : (
                        <textarea
                          onChange={e =>
                            handleChange(e, isSummerSchool ? 3 : 5)
                          }
                          name="activities"
                          value={aboutArray?.activities || ""}
                          className="school-info-textarea"
                        >
                          {aboutArray?.activities || ""}
                        </textarea>
                      )}
                    </div>

                    <button
                      className="w-100 show-all-button save-info-btn students-info-btn school-admin-info-btn"
                      type="submit"
                    >
                      <div>{t("main.userProfileMap.save")}</div>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default AdminInfoAboutSection
