import React from "react"
import { useTranslation } from "react-i18next"
import CheckedIcon from "../../svg/check.inline.svg"
import Table from "../../components/Table"

const SchoolItemSubjectsSection = props => {
  const certs = props.certs || []
  const subjects = props.subjects || []
  const { t } = useTranslation()

  let redundantSubjects = []
  subjects.forEach(subject => {
    if (!subject.certificatesIds && props.isSchoolPage) {
      redundantSubjects.push(subject.value)
    }
  })

  let allUsedCertificates = [...new Set(subjects
    .map(sub => {
      if (sub.certificatesIds && props.isSchoolPage) {
        return (sub.certificatesIds)
      }
    })
    .flat(Infinity))]
    .filter(element => {
      return element !== undefined;
    })

  let trimmedCertificates = []
  if (allUsedCertificates.length > 6) {
    trimmedCertificates = allUsedCertificates.slice(6, allUsedCertificates.length)
    allUsedCertificates = allUsedCertificates.slice(0, 6)
  }

  function changeChecked(subj, cert, index) {
    if (props.changeChecked) {
      props.changeChecked(subj, cert, index)
    }
  }

  return (
    <>
      {props.isSchoolPage ?
        <div
          className={`white-block  ${!props.style ? "about-school-block" : null}`}
        >
          <div
            className={`${!props.style
              ? "about-school-content-wrapper"
              : "about-school-content-wrapper-prof"
              } f-js container`}
          >
            <div className=" about-school-info">
              <div className="key-data-title school-info-title">
                {t("school.subjects.subjectsTitle")}
              </div>
              {/* <!---- SUBJECTS TABLE----> */}
              <table className="school-item-table">

                <tr>
                  <th className="empty-section"></th>
                  {certs.map(c => (
                    <>
                      {allUsedCertificates.includes(c.id) &&
                        <th key={c.id}>
                          <div className="school-comparison-item-wrapper">
                            <div className="school-comparison-item-title">
                              {c.value}
                            </div>
                          </div>
                        </th>
                      }
                    </>
                  ))}
                  {trimmedCertificates.length > 0 &&
                    <th>
                      <div className="school-comparison-item-wrapper">
                        <div className="school-comparison-item-title">
                          {t("school.subjects.other")}
                        </div>
                      </div>
                    </th>
                  }
                </tr>
                {subjects
                  .sort((a, b) => a.value.localeCompare(b.value))
                  .map((subj, index) => (
                    <>
                      {(!redundantSubjects.includes(subj.value)) &&
                        <tr key={subj.subjectId}>
                          <td className="row-title ">{subj.value}</td>
                          {allUsedCertificates.map(c => {
                            const isChecked = (subj.certificatesIds || []).includes(
                              c
                            )
                            return (
                              <td
                                key={c}
                              >
                                <CheckedIcon
                                  className={`${isChecked ? "checked-icon" : "not-checked"
                                    }`}
                                />
                              </td>
                            )
                          })}
                          {trimmedCertificates.length > 0 &&
                            <td>
                              <CheckedIcon
                                className={`${subj.certificatesIds
                                  .some(item => trimmedCertificates.includes(item))
                                  ? "checked-icon" : "not-checked"
                                  }`}
                              />
                            </td>
                          }
                        </tr>
                      }
                    </>

                  ))}

              </table>
            </div>
          </div>
        </div>
        : <div
          className={`white-block  ${!props.style ? "about-school-block" : null}`}
        >
          <div
            className={`${!props.style
              ? "about-school-content-wrapper"
              : "about-school-content-wrapper-prof"
              } f-js container`}
          >
            <div className=" about-school-info">
              <div className="key-data-title school-info-title">
                {t("school.subjects.subjectsTitle")}
              </div>
              {/* <!---- SUBJECTS TABLE----> */}
              <div className="container mt-4">
                <Table headers={certs} data={subjects} changeChecked={changeChecked} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default SchoolItemSubjectsSection



