import React, { useEffect, useState } from "react"
import axios from "../../http/axios"
import { useCurrentUser, isBrowser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"

let ReactQuill = null
if (isBrowser()) {
  ReactQuill = require("react-quill")
}

import { notification } from "antd"

const AdminScholarshipsSection = () => {
  const { currentUser } = useCurrentUser()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [scholarships, setScholarships] = useState({
    conditions: ""
  })

  useEffect(async () => {
    if (currentUser) {
      await axios
        .get(`/schools/${currentUser.schoolId}/scholarships` + `?lang=${lang}`)
        .then(res => {
          if (res.data) {
            setScholarships(res.data)
          }
        })
    }
  }, [currentUser])

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    const resultItem = {
      conditions: scholarships?.conditions,
      languageCode: lang,
    }
    axios.post(`/schools/${currentUser.schoolId}/scholarships`, {
      ...scholarships,
      ...resultItem,
    }).then(() => {
      openNotification("topRight")
    })
  }

  const handleChange = (e) => {
    scholarships.conditions = e
  }

  return (
    <div>
      <div className="info-overview-wrapper f-js px-4">
        <div className="info-overview-content-wrapper">
          <div className="school-info-title right-title">
            {t("school.header.scholarships")}
          </div>

          <div className="school-text-areas-wrapper">
            <div className="school-data-text-area-section" style={{ marginBottom: "40px" }}>
              <form onSubmit={handleFormSubmit}>
                <div className="school-data-text-area-section">
                  <div className="school-data-section-title">
                    {t("school.header.conditions")}
                  </div>
                  {isBrowser() ? (
                    <ReactQuill
                      theme="snow"
                      value={scholarships?.conditions}
                      onChange={e => handleChange(e)}
                    />
                  ) : (
                    <textarea
                      onChange={e => handleChange(e)}
                      name="conditions"
                      value={scholarships?.conditions}
                      className="school-info-textarea"
                    >
                      {scholarships?.conditions}
                    </textarea>
                  )}
                </div>

                <button
                  className="show-all-button w-100 save-info-btn students-info-btn school-admin-info-btn"
                  type="submit"
                >
                  <div>{t("main.userProfileMap.save")}</div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default AdminScholarshipsSection
